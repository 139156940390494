import { prop } from 'ramda';

/**
 * @public
 * Retrieves the current visible status of the navigation bar.
 *
 * @param {state} state - Current navigationReducer State Tree
 *
 * @returns {string} - retrieves the current state of navigation visibility
 */
const getNavVisibility = (state) => prop('visible', state);

/**
 * @public
 * Retrieves the current notifications for the navBar.
 *
 * @param {state} state - Current navigationReducer State Tree
 *
 * @returns {string} - retrieves the current notifications
 */
const getNotifications = (state) => prop('notifications', state);

export { getNavVisibility, getNotifications };
