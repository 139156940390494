let origin = null;

/* DocUp Actions */
origin = 'docUpload';
const FILE_ADDED = `${origin}/fileAdd`;
const FILE_REMOVED = `${origin}/fileRemove`;
const TABS_SWITCHED = `${origin}/tabsSwitched`;
const SUBMIT_DOCS = `${origin}/submitDocs`;
const SUBMIT_DOC = `${origin}/submitDoc`;
const SUBMIT_DOCS_SUCCESS = `${origin}/submitDocsSuccess`;
const UPLOAD_STARTED = `${origin}/uploadStarted`;
const UPLOAD_PROGRESS = `${origin}/uploadProgress`;
const UPLOAD_SUCCESS = `${origin}/uploadSuccess`;
const UPLOAD_FAILURE = `${origin}/uploadFailure`;
const UPLOADS_IN_PROGRESS = `${origin}/uploadsInProgress`;

export {
  FILE_ADDED,
  FILE_REMOVED,
  TABS_SWITCHED,
  SUBMIT_DOCS,
  SUBMIT_DOC,
  SUBMIT_DOCS_SUCCESS,
  UPLOAD_STARTED,
  UPLOAD_PROGRESS,
  UPLOAD_SUCCESS,
  UPLOAD_FAILURE,
  UPLOADS_IN_PROGRESS,
};
