import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideDialog } from '../../../store/dialog/dialog.actions';
import AlertPopup from '@aib/uikit-react/dist/Content/AlertPopup/AlertPopup';
import dialogConfigFactory from './dialogConfigFactory';
import langCommon from '../../lang.common.json';

const lang = {
  ERROR_HEAD: 'Document Upload Error',
  CONTENT_1: 'There was an error attempting to upload ',
  CONTENT_2: ' out of ',
  CONTENT_3:
    ' documents. Please try again later. (Maximum file size 10MB; Supported file types are ',
};

function DocUploadErrorDialog({ failCounter, totalFiles, hideDialog }) {
  const [visible, setVisible] = useState(false);
  const content =
    lang.CONTENT_1 +
    failCounter +
    lang.CONTENT_2 +
    totalFiles +
    lang.CONTENT_3 +
    langCommon.SUPPORTED_DOCUP_FILE_TYPES +
    '.)';
  const dialogConfig = dialogConfigFactory({
    heading: lang.ERROR_HEAD,
    content,
    accept: langCommon.OK,
  });

  useEffect(() => {
    let timeoutId;

    timeoutId = setTimeout(() => setVisible(true), 0);

    return () => {
      timeoutId = clearInterval(timeoutId);
    };
  });

  return (
    <AlertPopup
      id="DocumentUploadDialog"
      title={dialogConfig.heading}
      confirmLabel={dialogConfig.accept}
      cancelLabel=""
      isOpen={visible}
      onCancel={hideDialog}
      onConfirm={hideDialog}
    >
      {dialogConfig.content}
    </AlertPopup>
  );
}

DocUploadErrorDialog.propTypes = {
  hideDialog: PropTypes.func.isRequired,
  failCounter: PropTypes.number.isRequired,
  totalFiles: PropTypes.number.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  failCounter: ownProps.failCounter,
  totalFiles: ownProps.totalFiles,
});

const mapDispatchToProps = {
  hideDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocUploadErrorDialog);
