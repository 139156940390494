import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideDialog } from '../../../store/dialog/dialog.actions';
import AlertPopup from '@aib/uikit-react/dist/Content/AlertPopup/AlertPopup';
import { confirmLogoutClickAction } from '../../../store/session';
import dialogConfigFactory from './dialogConfigFactory';

function LogoutDialog({ onAccept, onCancel }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timeoutId;

    timeoutId = setTimeout(() => setVisible(true), 0);

    return () => {
      timeoutId = clearInterval(timeoutId);
    };
  });

  const dialogConfig = dialogConfigFactory({
    heading: 'Log out of your account?',
    visible: true,
    accept: 'Log Out',
    cancel: 'Cancel',
  });

  return (
    <AlertPopup
      id="LogoutDialog"
      title={dialogConfig.heading}
      confirmLabel={dialogConfig.accept}
      cancelLabel={dialogConfig.cancel}
      isOpen={visible}
      onConfirm={onAccept}
      onCancel={onCancel}
    />
  );
}

LogoutDialog.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch) => ({
  onAccept: () => {
    dispatch(hideDialog());
    dispatch(confirmLogoutClickAction());
  },
  onCancel: () => {
    dispatch(hideDialog());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoutDialog);
