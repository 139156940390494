import * as messagesTypes from '../messages/messages.types';
import { createLoadingReducer } from './showLoading';
import {
  FETCH_DOCUMENTS,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAILURE,
} from '../documents/documents.types';

/* Define which actions trigger the background tasks spinner */
const actions = [
  [FETCH_DOCUMENTS, FETCH_DOCUMENTS_SUCCESS, FETCH_DOCUMENTS_FAILURE],
  [
    messagesTypes.SUBMIT_THREAD_REPLY,
    messagesTypes.SUBMIT_THREAD_REPLY_SUCCESS,
    messagesTypes.SUBMIT_THREAD_REPLY_FAILURE,
  ],
  [
    messagesTypes.FETCH_THREADS,
    messagesTypes.FETCH_THREADS_SUCCESS,
    messagesTypes.FETCH_THREADS_FAILURE,
  ],
  [
    messagesTypes.FETCH_THREAD,
    messagesTypes.FETCH_THREAD_SUCCESS,
    messagesTypes.FETCH_THREAD_FAILURE,
  ],
  [
    messagesTypes.POST_THREAD,
    messagesTypes.POST_THREAD_SUCCESS,
    messagesTypes.POST_THREAD_FAILURE,
  ],
];

const reducerName = 'backgroundLoading';

const backgroundLoadingReducer = createLoadingReducer(actions, reducerName);

/* Selectors */
const showBackgroundLoading = (state = { [reducerName + 'Reducer']: [] }) => {
  return state[reducerName + 'Reducer'].length > 0;
};
export { showBackgroundLoading, backgroundLoadingReducer };
