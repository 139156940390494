import * as types from './openBanking.types';

const closeAccordion = () => ({ type: types.CLOSE_ACCORDION });
const startOpenBanking = () => ({ type: types.START_OB });
const nextStep = () => ({ type: types.NEXT_STEP });
const prevStep = () => ({ type: types.PREV_STEP });
const showModal = () => ({ type: types.SHOW_MODAL });
const hideModal = () => ({ type: types.HIDE_MODAL });
const hideErrorModal = () => ({ type: types.HIDE_ERROR_MODAL });
const fetchProviders = () => ({
  type: types.FETCH_PROVIDERS,
});
const storeProviders = (response) => ({
  type: types.FETCH_PROVIDERS_SUCCESS,
  data: response.resp,
});
const parseQueryString = () => ({ type: types.PARSE_QUERYSTRING });
const toggleAccordion = (accordionId) => ({
  type: types.TOGGLE_ACCORDION,
  accordionId,
});
const fetchProviderUrl = (
  cif,
  provider,
  redirectUrl,
  documentTypeId,
  conditionRefId
) => ({
  type: types.FETCH_PROVIDER_URL,
  cif,
  provider,
  redirectUrl,
  documentTypeId,
  conditionRefId,
});
const fetchProviderUrlSuccess = () => ({
  type: types.FETCH_PROVIDER_URL_SUCCESS,
});
const fetchProviderUrlFailure = (response) => ({
  type: types.FETCH_PROVIDER_URL_FAILURE,
  err: response?.err,
});
const selectProvider = (data) => ({
  type: types.SELECT_PROVIDER,
  data,
});

/**
 *
 * @param {*} status querystring param
 * @param {*} result querystring param
 * @param {*} taskId querystring param
 * @param {*} conditionRefId conditionRefId from device storage
 * @param {*} consentId consentId from device storage
 * @returns action
 */
const interceptRedirect = (
  status,
  result,
  taskId,
  conditionRefId,
  consentId
) => ({
  type: types.INTERCEPT_REDIRECT,
  status,
  result,
  taskId,
  conditionRefId,
  consentId,
});

const startPdfGeneration = (conditionReferenceId) => ({
  type: types.START_PDF_GENERATION,
  conditionReferenceId,
});

export {
  startOpenBanking,
  toggleAccordion,
  closeAccordion,
  nextStep,
  prevStep,
  showModal,
  hideModal,
  fetchProviders,
  storeProviders,
  selectProvider,
  fetchProviderUrl,
  fetchProviderUrlSuccess,
  fetchProviderUrlFailure,
  hideErrorModal,
  parseQueryString,
  interceptRedirect,
  startPdfGeneration,
};
