import { path } from 'ramda';
import * as types from './persistentState.types';
import * as docUploadTypes from '../docUpload/docUpload.types';
import migrate from './persistentState.migrate';

function persistentStateReducer(state = {}, action = {}) {
  switch (action.type) {
    case types.STATE_STORE:
    case docUploadTypes.UPLOAD_SUCCESS: {
      const conditionReferenceId = path(
        ['data', 'conditionReferenceId'],
        action
      );

      if (!Number.isInteger(conditionReferenceId)) {
        return state;
      }

      const conditionIdsWithDocsUploaded = {
        ...state.conditionIdsWithDocsUploaded,
      };
      conditionIdsWithDocsUploaded[conditionReferenceId] = true;

      return { ...state, conditionIdsWithDocsUploaded };
    }
    case types.HIDE_WELCOME_MESSAGE: {
      return {
        ...state,
        hasViewedWelcomeMessage: {
          ...state.hasViewedWelcomeMessage,
          [action.userId]: true,
        },
        hasViewedInfoMessage: {
          ...state.hasViewedInfoMessage,
          [action.userId]: true,
        },
      };
    }
    case types.HIDE_INFO_MESSAGE: {
      return {
        ...state,
        hasViewedInfoMessage: {
          ...state.hasViewedInfoMessage,
          [action.userId]: true,
        },
      };
    }
    case types.SAVE_MESSAGE_AS_DRAFT: {
      if (!action.data) return { ...state };
      return {
        ...state,
        threadsWithDraftMessages: {
          ...state.threadsWithDraftMessages,
          [action.data.key]: action.data.text,
        },
      };
    }
    case types.DELETE_DRAFT_MESSAGE: {
      const newState = { ...state };
      delete newState.threadsWithDraftMessages[action.data.key];

      return newState;
    }
    case types.STATE_GET_SUCCESS: {
      const newState = migrate(action.data);
      return newState;
    }
    case types.STATE_GET_FAILURE: {
      return state;
    }
    default:
      return state;
  }
}

export default persistentStateReducer;
