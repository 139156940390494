const origin = 'openBanking';

const START_OB = `${origin}/startOB`;
const TOGGLE_ACCORDION = `${origin}/toggleAccordion`;
const CLOSE_ACCORDION = `${origin}/closeAccordion`;

const NEXT_STEP = `${origin}/nextStep`;
const PREV_STEP = `${origin}/prevStep`;

const SHOW_MODAL = `${origin}/showModal`;
const HIDE_MODAL = `${origin}/hideModal`;

const SHOW_ERROR_MODAL = `${origin}/showErrorModal`;
const HIDE_ERROR_MODAL = `${origin}/hideErrorModal`;

const FETCH_PROVIDERS_SUCCESS = `${origin}/fetchProvidersSuccess`;
const FETCH_PROVIDERS_FAILURE = `${origin}/fetchProvidersFailure`;
const FETCH_PROVIDERS = `${origin}/fetchProviders`;
const SELECT_PROVIDER = `${origin}/selectProvider`;

const FETCH_PROVIDER_URL_SUCCESS = `${origin}/fetchProviderURLSuccess`;
const FETCH_PROVIDER_URL_FAILURE = `${origin}/fetchProviderURLFailure`;
const FETCH_PROVIDER_URL = `${origin}/fetchProviderURL`;

const INTERCEPT_REDIRECT = `${origin}/interceptRedirect`;
const INTERCEPT_REDIRECT_FAILURE = `${origin}/interceptRedirectFailure`;

const PARSE_QUERYSTRING = `${origin}/parseQueryString`;

const START_PDF_GENERATION = `${origin}/startPdfGeneration`;
const START_PDF_GENERATION_FAILURE = `${origin}/startPdfGenerationFailure`;

const PDF_GENERATION_FAILURE = `${origin}/pdfGenerationFailure`;

export {
  START_OB,
  TOGGLE_ACCORDION,
  CLOSE_ACCORDION,
  NEXT_STEP,
  PREV_STEP,
  SHOW_MODAL,
  HIDE_MODAL,
  FETCH_PROVIDERS,
  FETCH_PROVIDERS_SUCCESS,
  FETCH_PROVIDERS_FAILURE,
  SELECT_PROVIDER,
  FETCH_PROVIDER_URL_SUCCESS,
  FETCH_PROVIDER_URL_FAILURE,
  FETCH_PROVIDER_URL,
  SHOW_ERROR_MODAL,
  HIDE_ERROR_MODAL,
  INTERCEPT_REDIRECT,
  INTERCEPT_REDIRECT_FAILURE,
  PARSE_QUERYSTRING,
  START_PDF_GENERATION,
  START_PDF_GENERATION_FAILURE,
  PDF_GENERATION_FAILURE,
};
