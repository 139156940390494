import Utils from '../../lib/utils';
import * as types from './systemMaintenance.types';

const initialState = Utils.getStateTemplate('systemMaintenance');

function systemMaintenanceReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_SYSTEM_MAINTENANCE_DETAILS_SUCCESS:
      return action?.data;
    default:
      return state;
  }
}

export default systemMaintenanceReducer;
