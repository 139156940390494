import { assoc } from 'ramda';
import CookieUtils from '../lib/cookieUtils';

let applicationId = null;
let lastApiRequestTime = null;

export const setApplicationId = (id) => {
  window.KAMPYLE__MORTGAGE_REFERENCE_ID = applicationId = id;
};

export const setCustomerType = (custType) => {
  window.KAMPYLE__CUSTOMER_TYPE = custType;
};

export const setChannelDescription = (channelDescription) => {
  window.KAMPYLE__CHANNEL_DESCRIPTION = channelDescription;
};

const api = function api(url, options, headerOpts = {}) {
  const opts = assoc('credentials', 'include', options);

  const headers = new Headers(headerOpts);
  const csrfHeaders = CookieUtils.csrfHeaders();
  headers.append(csrfHeaders.name, csrfHeaders.token);
  headers.append('Accept', 'application/json'); // 'Accept' inform server all XHR expect json response
  opts.headers = headers;

  return fetch(url, opts)
    .then(
      (resp) => {
        lastApiRequestTime = Date.now();
        const contentType = resp.headers.get('content-type');
        if (resp.status === 403 || resp.status === 401) {
          // session is expired on server, redirect to home page
          CookieUtils.deleteCsrfCookies();
          window.location = '/?error=session-expired';
          return false;
        }
        if (resp.ok) {
          if (contentType && contentType.indexOf('application/json') !== -1) {
            return resp.json().catch((err) => Promise.reject(err));
          }
          return { ok: true, resp };
        }
        return Promise.reject(resp);
      },
      (err) => Promise.reject(err)
    )
    .then((resp) => {
      if (resp === undefined) {
        return Promise.reject({ ok: false }); // eslint-disable-line prefer-promise-reject-errors
      }
      if (resp && Object.prototype.hasOwnProperty.call(resp, 'ok')) {
        return resp;
      }
      return { ok: true, resp };
    })
    .catch((err) => {
      if (err.message) {
        return err.message;
      }
      // wrap in 'resp' to maintain consistency
      return err.json().then((data) => ({ resp: data }));
    });
};

export function getLastApiRequestTime() {
  return lastApiRequestTime;
}

export const logoutEndpoint = () =>
  api('/logout', {
    method: 'POST',
  });

export const fetchSystemMaintenanceData = () =>
  api('/credit/v1/mortgages/mcx-system-maintenance', {
    method: 'GET',
  });

export const fetchApplicationData = () =>
  api('/credit/v1/mortgages/application', {
    method: 'GET',
  });

export const refreshSession = () => {
  const opts = assoc('credentials', 'include', []);
  const headers = new Headers({});
  const csrfHeaders = CookieUtils.csrfHeaders();
  headers.append(csrfHeaders.name, csrfHeaders.token);
  opts.headers = headers;
  const refresh = new Date().getTime();

  return fetch(`/mortgages/verify-session?refresh=${refresh}`, opts).then(
    (resp) => {
      return { ok: ![401, 403].includes(resp.status), resp };
    }
  );
};

export const fetchThreadsData = () =>
  api(`/v1/message-service/${applicationId}/messagethreads`, {
    method: 'GET',
  });

export const fetchThreadData = (threadId) =>
  api(
    `/v1/message-service/${applicationId}/messagethreads/${threadId}/messages`,
    {
      method: 'GET',
    }
  );

export const fetchDocumentsData = () =>
  api(`/credit/v1/mortgages/${applicationId}/documents`, {
    method: 'GET',
  });

export const fetchNavNotifications = () =>
  api('/credit/v1/mortgages/notifications', {
    method: 'GET',
  });

export const fetchTasks = () =>
  api(`/v1/conditions-service/${applicationId}/conditions`, {
    method: 'GET',
  });

export const fetchPostUploadDocument = () => ({
  uri: `/credit/v1/mortgages/${applicationId}/documents`,
});

export const fetchPostSubmitThreadReply = (threadId, message) =>
  api(
    `/v1/message-service/${applicationId}/messagethreads/${threadId}/messages`,
    {
      method: 'POST',
      body: JSON.stringify({
        message,
      }),
    },
    {
      // Additional Headers
      'Content-Type': 'application/json',
    }
  ).then((resp) => resp);

export const fetchPutMarkThreadRead = (threadId) =>
  api(
    `/v1/message-service/${applicationId}/messagethreads/${threadId}/messages/markAsRead`,
    {
      method: 'PUT',
    },
    {
      // Additional Headers
      'Content-Type': 'application/json',
    }
  ).then((resp) => resp);

export const fetchPostSubmitThreadData = (messageSubject, message) =>
  api(
    `/v1/message-service/${applicationId}/messages`,
    {
      method: 'POST',
      body: JSON.stringify({
        messageSubject,
        message,
      }),
    },
    {
      // Additional Headers
      'Content-Type': 'application/json',
    }
  ).then((res) => res);

export const fetchPersistentState = (appId) =>
  api(`/credit/v1/mortgages/mcx-state/${applicationId}`, {
    method: 'GET',
  });

export const putPersistentState = (body) =>
  api(
    `/credit/v1/mortgages/mcx-state/${applicationId}`,
    {
      method: 'PUT',
      body: JSON.stringify(body),
    },
    {
      'Content-Type': 'application/json',
    }
  ).then((resp) => resp);

export const postApplication = (body) =>
  api(
    `/credit/v1/mortgages/application`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    {
      'Content-Type': 'application/json',
    }
  ).then((resp) => resp);

export const fetchProviders = () =>
  api('/mortgages/openbanking/providers', {
    method: 'GET',
  });

export const fetchProviderUrl = (
  cif,
  provider,
  documentTypeId,
  conditionReferenceId
) => {
  return api(
    `/mortgages/openbanking/authorisation/customer/${cif}`,
    {
      method: 'POST',
      body: JSON.stringify({
        providerName: provider.value,
        documentTypeId,
        conditionReferenceId,
      }),
    },
    {
      'Content-Type': 'application/json',
    }
  );
};

export const postStatement = (consentId, generateStatement, taskId) => {
  return api(
    `/mortgages/openbanking/statement`,
    {
      method: 'POST',
      body: JSON.stringify({
        consentId,
        generateStatement,
        taskId,
      }),
    },
    {
      'Content-Type': 'application/json',
    }
  );
};

export const postConnectApplication = (masApplicationId, oldAppId) => {
  const oldAppIdOptionalParam = oldAppId ? `?oldApplicationId=${oldAppId}` : '';
  return api(
    `/mortgages/application/ciam/connect${oldAppIdOptionalParam}`,
    {
      method: 'POST',
      body: JSON.stringify({ masApplicationId }),
    },
    {
      'Content-Type': 'application/json',
    }
  );
};

export const getProfileData = () => {
  return api(
    `/mortgages/ciam/profile`,
    {
      method: 'GET',
    },
    {
      'Content-Type': 'application/json',
    }
  );
};

export const fetchDashboardData = () =>
  api(`/mortgages/all-applications`, { method: 'GET' });

export { api };
