import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideDialog } from '../../../store/dialog/dialog.actions';
import AlertPopup from '@aib/uikit-react/dist/Content/AlertPopup/AlertPopup';

import { getDialogConfig } from './OpenBankingDialogConfig';

function OpenBankingErrorDialog({ hideDialog, errorCode }) {
  const [visible, setVisible] = useState(false);

  const dialogConfig = getDialogConfig(errorCode);

  useEffect(() => {
    let timeoutId;

    timeoutId = setTimeout(() => setVisible(true), 0);

    return () => {
      timeoutId = clearInterval(timeoutId);
    };
  });

  return (
    <AlertPopup
      id="open-banking-error"
      title={dialogConfig.heading}
      confirmLabel={dialogConfig.accept}
      cancelLabel={dialogConfig.cancel}
      isOpen={visible}
      onConfirm={hideDialog}
      onCancel={hideDialog}
    >
      {dialogConfig.content}
      {<div>{dialogConfig.msg}</div>}
    </AlertPopup>
  );
}

OpenBankingErrorDialog.propTypes = (state) => ({
  hideDialog: PropTypes.func.isRequired,
});

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  hideDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenBankingErrorDialog);
