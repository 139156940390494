import { prop, find, propEq, path, map, compose } from 'ramda';

const getThreadMessagesByThreadId = (messagesState, id) => {
  return path(['thread', id], messagesState);
};

const getThreadByThreadId = (messagesState, threadId) => {
  return find(propEq('messageThreadId', threadId))(messagesState.threads);
};

const getIsThreadLocked = (messagesState, threadId) => {
  const thread = getThreadByThreadId(messagesState, threadId);
  return thread.isLocked === 'Y';
};

/**
 * @public
 * Retrieves the currently active threads id.
 *
 * @param {object} messagesState - Current MessageViewReducer State Tree
 *
 * @returns {string} - retrieves the current active threads id
 */
const getCurrentThreadId = (messagesState) =>
  prop('currentThread', messagesState);

const getAreMessagesLoading = (messagesState) =>
  prop('areMessagesLoading', messagesState);

const getIsCreateMessageVisible = (messagesState) =>
  prop('isCreateMessageVisible', messagesState);

const getIsMessageSending = (messagesState) =>
  prop('isMessageSending', messagesState);

/**
 * @private
 * Retrieves the number of unread messages for the current thread
 * @param {object} state
 * @returns {int}
 */
const getCurrentThreadUnreadMessagesCount = (messagesState) => {
  const threadId = getCurrentThreadId(messagesState);
  const { threads = [] } = messagesState;
  const thread =
    threads.find(({ messageThreadId }) => messageThreadId === threadId) || {};

  const { messageCountUnreadByCustomer } = thread;

  return Number.isInteger(messageCountUnreadByCustomer)
    ? messageCountUnreadByCustomer
    : 0;
};

/**
 * @public
 * Retrieves the current thread/list of messages from the state tree
 * @param {object} messagesState - Current MessageViewReducer S State Tree
 *
 * @returns {object} - the Subject text or an empty string if nothign is found
 */
const getThreadList = (messagesState) => {
  return path(['thread', messagesState.currentThread], messagesState);
};

export const sortByDateModified = (list) =>
  sortByKey(list, 'dateModified', false, 'messageThreadId');

export const sortByKey = (list, key, asc = true, keyAlternative = null) => {
  const sorted = list.sort((a, b) => {
    const compared = compareIntField(a, b, key);
    return keyAlternative === null || compared !== 0
      ? compared
      : compareIntField(a, b, keyAlternative);
  });
  return asc ? sorted : sorted.reverse();
};

export const mapThreadDataToThread = (messageThread) => {
  const {
    messageThreadId: id,
    subject,
    dateCreated,
    dateModified,
    messageCount,
    messageCountUnreadByCustomer: unreadMessages,
    createdBy,
    modifiedBy,
  } = messageThread;

  const thread = {
    id,
    subject,
    dateCreated,
    createdBy,
    dateModified,
    modifiedBy,
    messageCount,
    unreadMessages,
  };
  return thread;
};

const getByModifiedDate = (list) =>
  compose(map(mapThreadDataToThread), sortByDateModified)(list);

const getMessageById = (threadId, id, messagesState) => {
  return find(propEq('id', id))(messagesState.thread[threadId]);
};

/**
 * Compare two objects by subtractiong the value of an integer field specified in the third parameter
 * @param {*} a - first object to compare
 * @param {*} b - second object to compare
 * @param {*} fieldName - name of the field used to compare the two objects
 */
const compareIntField = (a, b, fieldName) => {
  return parseInt(a[fieldName], 10) - parseInt(b[fieldName], 10);
};

export {
  getThreadMessagesByThreadId,
  getCurrentThreadId,
  getAreMessagesLoading,
  getIsMessageSending,
  getIsCreateMessageVisible,
  getThreadList,
  getThreadByThreadId,
  getMessageById,
  getByModifiedDate,
  getCurrentThreadUnreadMessagesCount,
  getIsThreadLocked,
};
