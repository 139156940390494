export default function (oldStateStructure) {
  const { hasViewedWelcomeMessage, conditionIdsWithDocsUploaded } =
    oldStateStructure;

  return {
    __schemaVersion: '9.0.0',
    hasViewedWelcomeMessage,
    hasViewedInfoMessage: false,
    conditionIdsWithDocsUploaded,
    threadsWithDraftMessages: {},
  };
}
