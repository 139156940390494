import React, { Component } from 'react';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { isPrd } from './lib/envUtil';
import LandscapeOverlay from './components/Animations/LandscapeOverlay/LandscapeOverlay';
import asyncComponent from './components/Async/AsyncComponent';
import SplashPage from './components/Login/SplashPage/SplashPage';
import ConfirmMobile from './components/Login/ConfirmMobile/ConfirmMobile';
import ConnectApplicationConnected from './components/Login/ConnectApplication/ConnectApplicationConnected';
import { createGlobalStyle } from 'styled-components';
import * as S from './App.styled';
import packageJson from '../package.json';
import {
  MCX_CALCULATOR,
  SWITCHER_CALC,
  APPOINTMENT_BOOKING,
  CONFIRM_MOBILE,
  CONNECT_APPLICATION,
  LOG_IN_REGISTER,
  DIGITAL_MORTGAGE_PROTECTED,
  DIGITAL_MORTGAGE_UNPROTECTED,
  DIGITAL_MORTGAGE_LOGOUT_LANDING,
  MANAGE_PROFILE_LANDING,
  TASKS,
  DOCUMENTS,
  MESSAGES,
  ROOT,
  SUBMISSION_IN_PROGRESS,
  DASHBOARD,
  VERIFY_SESSION,
} from './lib/urls';
import { getRedirectToUrl } from '@aib/mcx-shared-fe/dist/utils/functions/urlUtils';
import AppContext from './AppContext';
import SessionWrapper from './components/Session/SessionWrapper/SessionWrapper';

console.info(packageJson.name, packageJson.version);

/**
 * Hide Medlallia Feedback button inside of My Mortgage.
 */
const HideMedalliaStyle = createGlobalStyle`
  #kampyleButtonContainer {
    display: none;
  }

  #formLightboxContainer {
    display: none;
  }
`;

const AsyncAppAfterLogin = asyncComponent(() =>
  import(/* webpackChunkName: 'after-login' */ './AppAfterLogin')
);

const AsyncCalculatorPage = asyncComponent(() =>
  import(
    /* webpackChunkName: 'calculator' */ './components/Calculator/CalculatorWrapper'
  )
);

const AsyncSwitcherPage = asyncComponent(() =>
  import(
    /* webpackChunkName: 'switcher' */ './components/Calculator/SwitcherWrapper'
  )
);

const AsyncAppointmentBookingPage = asyncComponent(() =>
  import(
    /* webpackChunkName: 'switcher' */ './components/AppointmentBooking/AppointmentBookingWrapper'
  )
);

const AsyncDigitalMortgageApp = asyncComponent(() =>
  import(
    /* webpackChunkName: 'digital-mortgage' */ './components/DigitalMortgage/DigitalMortgageWrapper'
  )
);

const queryParams = new URLSearchParams(location.search);
const mcxCalcId = queryParams.get('mcxCalcId');
const isVersionVisible = !isPrd();
const savedStateId = queryParams.get('savedStateId');

const isMobileAppQuery = queryParams.get('isMobileApp');
const isMobileApp =
  isMobileAppQuery === 'true' || isMobileAppQuery === 'false'
    ? isMobileAppQuery === 'true'
    : sessionStorage.getItem('isMobileApp') === 'true';
sessionStorage.setItem('isMobileApp', isMobileApp);

class App extends Component {
  render() {
    return (
      <AppContext.Provider value={{ isMobileApp }}>
        <HideMedalliaStyle />
        <S.GlobalAppStyles />
        <S.GlobalFontFamilyStyles />
        <LandscapeOverlay />
        <BrowserRouter>
          <Switch>
            <Route path={VERIFY_SESSION} exact />
            <Route path={LOG_IN_REGISTER} exact component={SplashPage} />
            <Route path={ROOT} exact component={SplashPage} />
            <Route path={CONFIRM_MOBILE} exact component={ConfirmMobile} />
            <Route
              path={CONNECT_APPLICATION}
              exact
              component={ConnectApplicationConnected}
            />
            <Route path={MANAGE_PROFILE_LANDING} exact>
              {() => {
                const redirectTo = getRedirectToUrl();
                console.info(`redirect to ${redirectTo}`);
                location.href = redirectTo;
                return <></>;
              }}
            </Route>

            <Route
              path={[
                DIGITAL_MORTGAGE_PROTECTED,
                DIGITAL_MORTGAGE_UNPROTECTED,
                DIGITAL_MORTGAGE_LOGOUT_LANDING,
              ]}
              render={(props) => (
                <SessionWrapper>
                  {(hasActiveSession, profileData) => (
                    <AsyncDigitalMortgageApp
                      {...props}
                      mcxCalcId={mcxCalcId}
                      isMobileApp={isMobileApp}
                      isVersionVisible={isVersionVisible}
                      savedStateId={savedStateId}
                      activeSession={hasActiveSession}
                      profileData={profileData}
                    />
                  )}
                </SessionWrapper>
              )}
            />

            <Route
              path={MCX_CALCULATOR}
              render={(props) => (
                <SessionWrapper>
                  {(hasActiveSession, profileData) => (
                    <AsyncCalculatorPage
                      {...props}
                      isVersionVisible={isVersionVisible}
                      activeSession={hasActiveSession}
                      profileData={profileData}
                    />
                  )}
                </SessionWrapper>
              )}
            />

            <Route
              path={SWITCHER_CALC}
              render={(props) => (
                <SessionWrapper>
                  {(hasActiveSession, profileData) => (
                    <AsyncSwitcherPage
                      {...props}
                      eventListener={(a) => console.log(a)}
                      isVersionVisible={isVersionVisible}
                      activeSession={hasActiveSession}
                      profileData={profileData}
                    />
                  )}
                </SessionWrapper>
              )}
            />

            <Route
              path={`${APPOINTMENT_BOOKING}-new`}
              render={(props) => (
                <AsyncAppointmentBookingPage
                  {...props}
                  isNewBooking={true}
                  isVersionVisible={isVersionVisible}
                />
              )}
            />
            <Route
              path={`${APPOINTMENT_BOOKING}-change`}
              render={(props) => (
                <AsyncAppointmentBookingPage
                  {...props}
                  isNewBooking={false}
                  isVersionVisible={isVersionVisible}
                />
              )}
            />

            {/* After login, we get redirected to /contextRoot/tasks or /contextRoot/submission-in-progress */}
            {/* The react app doesn't yet support contextRoots so we strip it out again */}
            <Redirect from={`${ROOT}:contextRoot/tasks`} to={TASKS} />
            <Redirect
              from={`${ROOT}:contextRoot/submission-in-progress`}
              to={SUBMISSION_IN_PROGRESS}
            />
            <Route
              path={SUBMISSION_IN_PROGRESS}
              render={(props) => (
                <AsyncDigitalMortgageApp
                  isMobileApp={isMobileApp}
                  isVersionVisible={isVersionVisible}
                />
              )}
            />
            <Route path={TASKS} component={AsyncAppAfterLogin} />
            <Route path={DOCUMENTS} component={AsyncAppAfterLogin} />
            <Route path={MESSAGES} component={AsyncAppAfterLogin} />
            <Route path={DASHBOARD} exact component={AsyncAppAfterLogin} />
            <Redirect from="/" to={ROOT} />
          </Switch>
        </BrowserRouter>
      </AppContext.Provider>
    );
  }
}

export default hot(module)(App);
