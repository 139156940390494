import { css } from 'styled-components';
import {
  navMenuTopHeight,
  navMenubottomHeight,
  toolbarHeight,
} from './variables.styled';
import { aibScrollbar } from '@aib/uikit-react/dist/StyleSnippets/ScrollBar';
import { breakpoint } from '@aib/design-tokens';

/* All pages must have this to allow content
 * to appear correctly between the menu(s)
 */
export const desktopMainSection = css`
  margin-bottom: 0;
  height: calc(100% - ${navMenuTopHeight});
  padding-top: ${navMenuTopHeight};
`;

export const mainSection = css`
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  margin-bottom: ${navMenubottomHeight};
  height: calc(100% - ${navMenubottomHeight} - ${toolbarHeight});
  padding-top: ${toolbarHeight};

  @media (min-width: ${breakpoint.medium}) {
    ${aibScrollbar};
  }

  @media (min-width: ${breakpoint.xLarge}) {
    ${desktopMainSection};
  }
`;

export const mainSectionNoBottomNav = css`
  margin-bottom: 0;
  height: calc(100% - ${toolbarHeight});

  @media (min-width: ${breakpoint.xLarge}) {
    ${desktopMainSection};
  }
`;

/*
 * Doc upload panel content spacing
 */
export const docUploadPanelPadding = css`
  padding: 1.5rem 1.5rem 0 1.5rem;

  @media (min-width: ${breakpoint.xLarge}) {
    padding: 1.5rem 3.5rem 0 3.5rem;
  }
`;

// TODO change the breakpoints to use design tokens:
export const layoutPadding = css`
  box-sizing: border-box;
  margin: 0 auto;
  padding: 1rem;

  @media all and (min-width: 0) and (max-width: 480px) and (orientation: landscape) {
    padding: 1.5rem;
  }

  @media (max-width: 839px) and (min-width: 480px) and (orientation: landscape) {
    padding: 1.5rem;
  }

  @media (max-width: 839px) and (min-width: 480px) {
    padding: 3rem;
  }

  @media (min-width: 840px) {
    padding: 3.75rem;
  }
`;
