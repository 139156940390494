import { path } from 'ramda';

export const getApplicationConnectFailed = (state) =>
  path(['ciamReducer', 'applicationConnectionFailed'], state);

export const getLoggedInUserId = (state) =>
  path(['ciamReducer', 'profile', 'ciamId'], state);

export const getProfileData = (state) =>
  path(['ciamReducer', 'profile'], state);
