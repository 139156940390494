import Cookies from 'js-cookie';

const COOKIE_NAMES = ['CSRF_HEADER_NAME', 'CSRF_TOKEN'];

/**
 * @Public
 * Delete csrf cookies
 */
const deleteCsrfCookies = () => {
  COOKIE_NAMES.forEach((cookieName) => Cookies.remove(cookieName));
};

const createMockCsrfCookies = () => {
  COOKIE_NAMES.forEach((cookieName) =>
    Cookies.set(cookieName, 'MOCK_COOKIE_VALUE')
  );
};

const csrfHeaders = () => {
  const csrfHeaderName = Cookies.get('CSRF_HEADER_NAME');
  const csrfToken = Cookies.get('CSRF_TOKEN');
  if (!csrfHeaderName || !csrfToken) {
    throw new Error('csrf cookies not found');
  }
  return { name: csrfHeaderName, token: csrfToken };
};

// Public API
export default {
  deleteCsrfCookies,
  createMockCsrfCookies,
  csrfHeaders,
};
