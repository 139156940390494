import { put, takeEvery, call } from 'redux-saga/effects';
import { fetchDocumentsData } from '../api';
import * as types from './documents.types';
import { showDialog } from '../dialog/dialog.actions';

function* getDocumentsData(fetchDocumentsDataFn) {
  try {
    const response = yield call(fetchDocumentsDataFn);
    if (response.ok) {
      const data = response.resp;
      yield put({ type: types.FETCH_DOCUMENTS_SUCCESS, data });
    } else {
      yield put({ type: types.FETCH_DOCUMENTS_FAILURE });
      yield put(showDialog('DOC_LIST_ERROR'));
    }
  } catch (e) {
    yield put({ type: types.FETCH_DOCUMENTS_FAILURE });
    yield put(showDialog('DOC_LIST_ERROR'));
  }
}

function* documentsSaga() {
  yield takeEvery(types.FETCH_DOCUMENTS, getDocumentsData, fetchDocumentsData);
}

export { getDocumentsData, documentsSaga };
