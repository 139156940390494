/**
 * Reader - A Getter/Setter for working with Maps
 */

class MapReader {
  constructor(id, map) {
    this.id = id;
    // Allowing empty map to build one dynamically
    this.map = map || new Map();
  }

  isEmpty(str) {
    return `${str}`.replace(/\s/g, '').length === 0;
  }

  get(key) {
    if (this.isEmpty(key) && process.env.NODE_ENV === 'development') {
      console.log('Utils.MapReader: Provided Key is Empty');
    }
    if (this.map.size < 1) {
      throw new Error('Utils.MapReader: No map defined');
    }
    if (!this.map.has(key) && process.env.NODE_ENV === 'development') {
      console.log(`Utils.MapReader: ${key} not found in ${this.id}`);
    }
    const val = this.map.get(key);
    return val || key;
  }

  set(key, value) {
    this.map?.set(key, value);
  }
}

export default MapReader;
