import * as persistentStateTypes from './persistentState/persistentState.types';
import * as persistentStateActions from './persistentState/persistentState.actions';
import * as docUploadTypes from './docUpload/docUpload.types';

const persistentStateMiddleware = (store) => (next) => (action) => {
  // eslint-disable-next-line
  if (!action.hasOwnProperty('type')) {
    console.error('action has no type', action);
    return;
  }

  switch (action.type) {
    /**
     * 4 types of info currently persisted:
     * - Welcome Popup Message
     * - Info Popup
     * - Docs Uploaded
     * - Draft messages
     **/
    case persistentStateTypes.HIDE_WELCOME_MESSAGE:
    case persistentStateTypes.HIDE_INFO_MESSAGE:
    case persistentStateTypes.SAVE_MESSAGE_AS_DRAFT:
    case persistentStateTypes.DELETE_DRAFT_MESSAGE:
    case persistentStateTypes.SYNC_STATE_WITH_MAS:
    case docUploadTypes.SUBMIT_DOCS_SUCCESS: {
      next(action);
      const { persistentStateReducer } = store.getState();
      if (typeof persistentStateReducer === 'undefined') {
        break;
      }

      store.dispatch(
        persistentStateActions.putPersistentState(persistentStateReducer)
      );
      break;
    }

    default:
      next(action);
  }
};

export default persistentStateMiddleware;
