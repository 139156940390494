import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

class LoginDirectly extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isVisible: false };
    this.fetchApplicationJson = this.fetchApplicationJson.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.gotoTasksPage = this.gotoTasksPage.bind(this);
    this.gotoTasksPage = this.gotoTasksPage.bind(this);
    this.gotoDocumentsPage = this.gotoDocumentsPage.bind(this);
    this.keyListener = this.keyListener.bind(this);
    this.setState = this.setState.bind(this);
    this.postForm = this.postForm.bind(this);
    this.keyPressSequence = '';
  }

  handleUsernameChange(event) {
    this.setState({ username: event.target.value });
  }

  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  gotoTasksPage() {
    this.props.history.push('/mortgages/tasks');
  }

  gotoDocumentsPage() {
    this.props.history.push('/mortgages/documents');
  }

  fetchApplicationJson() {
    // console.log('fetchApplicationJson');
    const { setState } = this;
    const { gotoTasksPage } = this;

    fetch('/credit/v1/mortgages/application')
      .then((res) => res.json())
      .then(function (response) {
        // console.log('response', response);
        gotoTasksPage();
      })
      .catch((error) => {
        console.error('Error:', error);
        setState({ isVisible: true });
      });
  }

  postForm() {
    // console.log('postForm()');
    const { username, password } = this.state;
    // console.log('username', username);
    // console.log('password', password);
    const _csrf = Cookies.get('CSRF_TOKEN');
    // console.log('_csrf', _csrf);
    const { gotoTasksPage, gotoDocumentsPage } = this;

    fetch('/mcxauth/login', {
      method: 'post',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: `_csrf=${_csrf}&username=${username}&password=${password}`,
    })
      .then((res) => res.json())

      .then((response) => {
        // console.log('form response', response);
        // console.log('goto /tasks');
        gotoTasksPage();
      })
      .catch((error) => {
        console.error('Error:', error);
        // console.log('goto /documents');
        gotoDocumentsPage();
      });
  }

  keyListener(event) {
    this.keyPressSequence += event.key;
    if (this.keyPressSequence.endsWith('logmein')) {
      // console.log('logmein');
      this.fetchApplicationJson();
    }
  }

  componentDidMount() {
    // console.log('componentDidMount');
    document.addEventListener('keydown', this.keyListener, false);
  }

  componentWillUnmount() {
    // console.log('componentWillUnmount');
    document.removeEventListener('keydown', this.keyListener, false);
  }

  render() {
    return (
      <div>
        <div style={{ display: this.state.isVisible ? 'block' : 'none' }}>
          <input
            type="text"
            name="username"
            onChange={this.handleUsernameChange}
          />
          <input
            type="password"
            name="password"
            onChange={this.handlePasswordChange}
          />
          <button onClick={this.postForm}>submit</button>
        </div>
      </div>
    );
  }
}

LoginDirectly.propTypes = {
  history: PropTypes.object,
};

export default LoginDirectly;
