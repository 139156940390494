import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideDialog } from '../../../store/dialog/dialog.actions';
import AlertPopup from '@aib/uikit-react/dist/Content/AlertPopup/AlertPopup';
import dialogConfigFactory from './dialogConfigFactory';
import langCommon from '../../lang.common.json';

const lang = {
  TRY_AGAIN: 'Try again with ',
  INVALID_FILETYPE: 'We do not accept files of this type.',
};

function DocUploadFileTypeNotAllowedDialog({ invalidTypeFiles, hideDialog }) {
  const [visible, setVisible] = useState(false);
  const strFileList = invalidTypeFiles.map((file) => file.name).join('\n');
  const content =
    lang.TRY_AGAIN +
    langCommon.SUPPORTED_DOCUP_FILE_TYPES +
    '.\n\n' +
    strFileList;
  const dialogConfig = dialogConfigFactory({
    heading: lang.INVALID_FILETYPE,
    content,
    accept: langCommon.OK,
  });

  useEffect(() => {
    let timeoutId;
    timeoutId = setTimeout(() => setVisible(true), 0);

    return () => {
      timeoutId = clearInterval(timeoutId);
    };
  });

  return (
    <AlertPopup
      id="DocumentUploadDialog"
      title={dialogConfig.heading}
      confirmLabel={dialogConfig.accept}
      cancelLabel=""
      isOpen={visible}
      onCancel={hideDialog}
      onConfirm={hideDialog}
    >
      {dialogConfig.content}
    </AlertPopup>
  );
}

DocUploadFileTypeNotAllowedDialog.propTypes = {
  hideDialog: PropTypes.func.isRequired,
  invalidTypeFiles: PropTypes.array.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  invalidTypeFiles: ownProps.invalidTypeFiles,
});

const mapDispatchToProps = {
  hideDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocUploadFileTypeNotAllowedDialog);
