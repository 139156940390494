import Utils from '../lib/utils';

const initialState = Utils.getStateTemplate('snackbar');

// Action Types
export const SHOW_SNACKBAR = 'snackbar/show';
export const HIDDEN_SNACKBAR = 'snackbar/hidden';

const createAction = (type) => (data) => ({ type, data });

// Actions
export const showSnackbarAction = createAction(SHOW_SNACKBAR);
export const hiddenSnackbarAction = () => ({ type: HIDDEN_SNACKBAR });

let instanceCounter = 0;

// Reducer
export const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      instanceCounter += 1;
      return [...state, { messageId: instanceCounter, ...action.data }];
    case HIDDEN_SNACKBAR:
      return state.slice(1);
    default:
      return state;
  }
};

/* Selectors */
export const selectSnackbarConfig = (state = { snackbarReducer: [] }) =>
  state.snackbarReducer.length > 0 ? state.snackbarReducer[0] : undefined;
