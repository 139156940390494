import styled, { css } from 'styled-components';
import { color, breakpoint, fontFamily } from '@aib/design-tokens';
import { layoutPadding } from '../../Theme/grid.styled';
import housesImg from '../../Theme/assets/images/houses.svg';
import aibLogoImg from '../../Theme/assets/images/aiblogo-flat.svg';
import Button from '@aib/uikit-react/dist/Form/Button/Button';
import infoIcon from '../../Theme/assets/images/info-purple.svg';

export const SplashPage = styled.div`
  height: 100%;

  @media (min-width: 576px) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 420px;
    height: 680px;
    margin: auto;
  }

  @media (min-width: ${breakpoint.xLarge}) {
    width: 700px;
    border: solid 1px ${color.grey02};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12),
      0 0 1px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const SplashPageBody = styled.div`
  ${layoutPadding};

  &&& {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    padding: 2rem;
  }

  @media (min-width: ${breakpoint.medium}) {
    background: ${color.baseWhite};
    position: relative;
  }

  @media (min-width: 576px) {
    border: 1px solid ${color.grey02};
    padding: 3rem;
    background: ${color.baseWhite};
    height: 100%;
    box-sizing: border-box;
  }
`;

export const SplashPageMain = styled.main`
  font-family: ${fontFamily.regular};
  -webkit-font-smoothing: antialiased;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.7) 100%
    ),
    url(${housesImg});
  opacity: 0;
  will-change: opacity;
  background-size: cover;
  height: 100%;
  background-repeat: repeat-x;
  background-position: center bottom;

  @media (min-width: ${breakpoint.medium}) {
    background-size: cover;
    background-position: center bottom;
  }

  ${(props) =>
    props.isVisible &&
    css`
      opacity: 1;
    `}
`;

export const SplashPageLogo = styled.div`
  width: 80px;
  height: 80px;
  background-image: url(${aibLogoImg});
`;

export const SplashPageHead = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SplashPageTitle = styled.h1`
  margin-top: 1.6rem;
  margin-bottom: 0.2rem;
  color: ${color.corePurple100};
  font-size: 1.6rem;
`;

export const SplashAdvisoryNotice = styled.div`
  background-color: #f8f4f8;
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  max-width: 300px;
  width: 100%;
  border-radius: 4px;
  font-family: ${fontFamily.medium};
`;

export const AdvisoryNoticeCell = styled.div`
  display: flex;
  flex-direction: list;
  margin: 0.75rem 0rem;
`;

export const AdvisoryNoticeText = styled.div`
  color: ${color.corePurple100};
  font-size: 12.5px;
  margin-right: 1.75rem;
`;

export const InfoIcon = styled.div`
  margin-left: 1.75rem;
  height: 15px;
  width: 15px;
  background-size: cover;
  background-image: url(${infoIcon});
`;

export const SplashPageCtas = styled.div`
  width: 100%;
  max-width: 300px;
  text-align: center;
`;

export const ButtonStyled = styled(Button)`
  margin-bottom: 1.5rem;
  width: 100%;
`;

export const DebugLinksWrapper = styled.div`
  position: fixed;
  top: env(safe-area-inset-top);
  z-index: 99999;
  padding: 15px;
  background-color: ${color.baseWhite}dd;
  height: 20px;
  overflow: hidden;
  border: 1px solid ${color.corePurple50};

  h2 {
    margin: 0;
  }
`;

export const DebugLinksLabel = styled.label`
  font-size: 18px;
  font-weight: 800;
  display: block;
`;

export const DebugLinksChevron = styled.span`
  position: absolute;
  right: 15px;
  top: 13px;
  &::before {
    content: '▼';
  }
`;

export const DebugLinksSwitch = styled.input`
  position: absolute;
  top: -10000px;
  left: -10000px;
  &:checked + div {
    height: auto;

    ${DebugLinksChevron} {
      &::before {
        content: '▲';
      }
    }
  }
`;
