import { assocPath, path, isEmpty, isNil } from 'ramda';
import {
  getDocUpUniqueKeyVal,
  appendNewFile,
  removeFileFromAdded,
  updateDocument,
} from './docUpload.selectors';

import Utils from '../../lib/utils';
import * as types from './docUpload.types';
import uploadStatuses from '../../lib/maps/uploadStatuses.json';

const initialState = Utils.getStateTemplate('docup');

function docUploadReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FILE_ADDED: {
      /*
       * Query which key determines uniqueness
       * as that will be our state key
       */
      const { docUpUniqueValue } = getDocUpUniqueKeyVal(action.data);
      /**
       * TODO: none of these side effects should be in the reducer
       * so a refactoring task is required to have this logic in the
       * action that was triggered so the data payload is passed such
       * that the reducer merely returns the updated state
       */
      const { currentApplicant, applicantNames } = action.data;

      const fileAppendedState = appendNewFile(
        docUpUniqueValue,
        action.data,
        state
      );

      const docsPath = ['documents', `${docUpUniqueValue}`];
      const applicantAddedPath = ['applicants', currentApplicant, 'added'];

      /**
       * If state already has documents added, use it to update props
       * otherwise initialise docUpload state with the docUpload state template
       */
      const docupDoctypeTmpl = Utils.getStateTemplate('docup_doctype');
      const docupAppTmpl = Utils.getStateTemplate('docup_applicant');
      // query setting for using familiar names
      const useFamiliarNames = Utils.getSetting('USE_APPLICANT_FIRSTNAMES');

      const docs = path(docsPath, state) || [];

      const template =
        !isEmpty(docs) && !isNil(docs)
          ? path(docsPath, state)
          : {
              ...docupDoctypeTmpl,
              applicants: applicantNames.reduce(
                (prev, applicantName, index) => {
                  const clone = Object.assign({}, prev);
                  // convert array's zero-indexed key to one-indexed for object
                  const keyPlus = `${parseInt(index, 10) + 1}`;
                  clone[keyPlus] = Object.assign({}, docupAppTmpl);
                  // using familiar names? if not revert to impersonal
                  clone[keyPlus].applicantName = useFamiliarNames
                    ? applicantName
                    : Utils.getMessageCode(clone[keyPlus].applicantName, [
                        keyPlus,
                      ]);
                  return clone;
                },
                {}
              ),
            };
      /**
       * Handle updating `added` for this FILE_ADDED
       */

      const applicantAddedDocs = assocPath(
        applicantAddedPath,
        path([...docsPath, ...applicantAddedPath], fileAppendedState),
        template
      );

      let nextState = assocPath(
        ['documents', 'currentApplicant'],
        currentApplicant,
        fileAppendedState
      );
      nextState = assocPath(docsPath, applicantAddedDocs, nextState);

      return nextState;
    }
    case types.FILE_REMOVED: {
      /*
       * Query which key determines uniqueness
       * as that will be our state key - note
       * any properties that conprise the uniqueness
       * need to be present in action.data
       */
      const { docUpUniqueValue } = getDocUpUniqueKeyVal(action.data);
      return removeFileFromAdded(docUpUniqueValue, action.data, state);
    }

    case types.SUBMIT_DOCS_SUCCESS: {
      const newState = assocPath(
        ['documents', action.conditionReferenceId, 'docsSubmitted'],
        true,
        state
      );
      return assocPath(
        ['documents', 'conditionIdWhereUploadIsInProgress'],
        0,
        newState
      );
    }
    case types.UPLOAD_SUCCESS: {
      const newState = updateDocument(
        action.data.conditionReferenceId,
        action.data.uniqueDocumentId,
        state,
        {
          uploadStatus: uploadStatuses.UPLOAD_SUCCESSFUL,
        }
      );
      return newState;
    }
    case types.UPLOAD_FAILURE: {
      const { uniqueDocumentId, errorCode, conditionReferenceId } = action.data;
      const newState = updateDocument(
        conditionReferenceId,
        uniqueDocumentId,
        state,
        {
          uploadStatus: uploadStatuses.UPLOAD_FAILED,
          uploadProgress: 0,
          errorCode,
        }
      );
      return assocPath(
        ['documents', 'conditionIdWhereUploadIsInProgress'],
        0,
        newState
      );
    }
    case types.UPLOAD_STARTED: {
      const newState = updateDocument(
        action.data.conditionReferenceId,
        action.data.uniqueDocumentId,
        state,
        {
          uploadStatus: uploadStatuses.IN_PROGRESS,
        }
      );
      return assocPath(
        ['documents', 'conditionIdWhereUploadIsInProgress'],
        action.data.conditionReferenceId,
        newState
      );
    }
    case types.UPLOAD_PROGRESS: {
      const newState = updateDocument(
        action.data.conditionReferenceId,
        action.data.uniqueDocumentId,
        state,
        {
          uploadProgress: action.data.progress,
        }
      );
      return newState;
    }
    case types.UPLOADS_IN_PROGRESS: {
      const { inProgress, conditionReferenceId } = action.data;

      return assocPath(
        ['documents', conditionReferenceId, 'uploadInProcess'],
        inProgress,
        state
      );
    }
    case types.TABS_SWITCHED: {
      const { value } = action.data;

      return assocPath(['documents', 'currentApplicant'], value, state);
    }
    default:
      return state;
  }
}

export default docUploadReducer;
