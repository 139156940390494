import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Utils from '../../../lib/utils';
import { getLastApiRequestTime } from '../../../store/api';

class KeepAlive extends React.Component {
  constructor(props) {
    super(props);
    this.keepAliveFrequency = Utils.getSetting('KEEP_ALIVE_FREQUENCY');
  }

  componentDidMount() {
    const events = ['click', 'keypress'];
    events.forEach((event) => {
      // don't track all actions, throttle
      // to prevent the backend from being overloaded.
      document.addEventListener(
        event,
        _.throttle(this.tryToKeepAlive, 1000 * this.keepAliveFrequency)
      );
    });
    this.keepAlive();
  }

  tryToKeepAlive = () => {
    const timeNow = new Date().getTime();
    const lastApiRequestTime = getLastApiRequestTime();
    const durationSinceLastApiRequest = timeNow - lastApiRequestTime;

    if (durationSinceLastApiRequest > this.keepAliveFrequency * 1000) {
      this.keepAlive();
    }
  };

  keepAlive() {
    this.props.onKeepAlive({ autoRedirect: this.props.autoRedirect });
  }

  render() {
    return false;
  }
}

KeepAlive.propTypes = {
  onKeepAlive: PropTypes.func.isRequired,
  autoRedirect: PropTypes.bool,
};

export default KeepAlive;
