import styled from 'styled-components';
import { color, zIndex } from '@aib/design-tokens';

export const BrowserSupport = styled.div`
  position: fixed;
  z-index: ${zIndex.pageError};
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 8px;
  border-bottom: 2px solid ${color.yellow100};
  background-color: ${color.yellow30};
`;

export const BrowserSupportContent = styled.div`
  display: flex;
  padding: 0 8px;
  max-width: 800px;
`;

export const BrowserSupportIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
`;

export const BrowserSupportText = styled.p`
  margin: 8px;
`;
