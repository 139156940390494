/**
 * Return a string representation of the type of the passed argument
 * Differs from native typeof operator in that it can distinguish
 * Arrays and Objects
 *
 * @param obj {Any} - A variable to determine the type of
 */
export const getType = (obj) => {
  const type = Object.prototype.toString.call(obj);
  return type.substring(type.indexOf(' ') + 1, type.indexOf(']')).toLowerCase();
};

/**
 * @private - data type checks return boolean
 */
const isUndefined = (x) => typeof x === 'undefined';
export const isNull = (x) => x === null;
// eslint-disable-next-line
export const isInteger = (n) => n << 0 === n;
export const isArray = (n) => getType(n) === 'array';
export const isObject = (n) => getType(n) === 'object';
export const isString = (n) => getType(n) === 'string';
export const hasProp = (prop, obj) =>
  Object.prototype.hasOwnProperty.call(obj, prop);

/**
 * @Public - determine if a variable is empty
 * if we can't tell, then return it
 *
 * @param {*} - the var to test
 * @returns {Boolean} the result of the test
 */
export const isEmpty = (n) => {
  if (isNull(n)) {
    return true;
  }
  if (isUndefined(n)) {
    return true;
  }
  if (isInteger(n)) {
    // any integer is inherently not empty
    return false;
  }
  if (isObject(n)) {
    return Object.keys(n).length === 0;
  }
  if (isArray(n) || isString(n)) {
    return n.length === 0;
  }
  // return a boolean even if there's no matches above
  return Boolean(n);
};
