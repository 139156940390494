const TOGGLE_NAV_VISIBILITY =
  '/homesApp/navigationReducer/toggleNavigationVisibility';
const GET_NAV_NOTIFICATIONS = '/homesApp/navigationReducer/getNavNotifications';
const GET_NAV_NOTIFICATIONS_SUCCESS =
  '/homesApp/navigationReducer/getNavNotificationsSuccess';
const GET_NAV_NOTIFICATIONS_FAILURE =
  '/homesApp/navigationReducer/getNavNotificationsFailure';
const SUB_READ_MESSAGES_FROM_NAV_NOTIFICATIONS =
  '/homesApp/navigationReducer/subReadMessagesFromNavNotifications';

export {
  TOGGLE_NAV_VISIBILITY,
  GET_NAV_NOTIFICATIONS,
  GET_NAV_NOTIFICATIONS_SUCCESS,
  GET_NAV_NOTIFICATIONS_FAILURE,
  SUB_READ_MESSAGES_FROM_NAV_NOTIFICATIONS,
};
