import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as S from './PageError.styled.js';

class PageError extends Component {
  constructor(props) {
    super(props);
    this.state = { isVisible: false };
  }

  componentDidMount() {
    if (this.props.visible) {
      this.setState({ isVisible: this.props.visible });
    }
  }

  componentDidUpdate(props) {
    if (this.props.visible !== props.visible) {
      this.setState({ isVisible: this.props.visible });
    }
  }

  render() {
    const { isVisible } = this.state;
    return (
      <S.PageError slideDown={isVisible}>
        <S.PageErrorInnerContainer>
          <S.PageErrorHeader>{this.props.title}</S.PageErrorHeader>
          <S.PageErrorMessage>{this.props.text}</S.PageErrorMessage>
        </S.PageErrorInnerContainer>
      </S.PageError>
    );
  }
}

PageError.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default PageError;
