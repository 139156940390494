import React, { useState } from 'react';
import * as S from './LandscapeOverlay.styled.js';
import IconClose from '@aib/uikit-react/dist/Icons/IconClose';
import { color } from '@aib/design-tokens';

const LANDSCAPE_OVERLAY_MESSAGE =
  'For the best experience \n please turn your device';

function LandscapeOverlay() {
  const [showOverlay, setShowOverlay] = useState(true);

  const onCloseOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <div className="LandscapeOverlay">
      {showOverlay && (
        <S.LandscapeOverlayContainer>
          <S.IconButtonStyled size="medium" onClick={onCloseOverlay}>
            <IconClose color={color.baseWhite} />
          </S.IconButtonStyled>
          {/* TODO add the original icon to the UI Kit */}
          <S.IconRetryStyled color={color.baseWhite} />
          <S.LandscapeOverlayText>
            {LANDSCAPE_OVERLAY_MESSAGE}
          </S.LandscapeOverlayText>
        </S.LandscapeOverlayContainer>
      )}
    </div>
  );
}

export default LandscapeOverlay;
