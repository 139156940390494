import * as types from './messages.types';

const getThreads = () => ({ type: types.FETCH_THREADS });

const deleteMessage = (threadId, messageId) => ({
  type: types.DELETE_MESSAGE,
  threadId,
  messageId,
});

const getThread = (id) => ({
  type: types.FETCH_THREAD,
  threadId: id,
});

const submitReply = (threadId, message) => ({
  type: types.SUBMIT_THREAD_REPLY,
  data: {
    threadId,
    message,
  },
});

const navigateTo = (viewType) => ({
  type: types.SWITCH_VIEW_TYPE,
  data: {
    view: viewType,
  },
});

const postThread = (subject, description) => ({
  type: types.POST_THREAD,
  data: {
    subject,
    description,
  },
});

const markThreadRead = (threadId) => ({
  type: types.MARK_THREAD_READ,
  data: {
    threadId,
  },
});

const markThreadReadSuccess = () => ({
  type: types.MARK_THREAD_READ_SUCCESS,
});

const markThreadReadFailure = (err) => ({
  type: types.MARK_THREAD_READ_FAILURE,
  err,
});

const resetCurrentThread = () => ({
  type: types.RESET_CURRENT_THREAD,
});

const showCreateMessage = () => ({
  type: types.SHOW_CREATE_MESSAGE,
});

const hideCreateMessage = () => ({
  type: types.HIDE_CREATE_MESSAGE,
});

export {
  deleteMessage,
  getThreads,
  getThread,
  submitReply,
  navigateTo,
  postThread,
  markThreadRead,
  markThreadReadSuccess,
  markThreadReadFailure,
  resetCurrentThread,
  showCreateMessage,
  hideCreateMessage,
};
