import { toolbarHeight as uikitToolbarHeight } from '@aib/uikit-react/dist/Navigation/Toolbar/Toolbar';

export const maxWidthConstraint = '1024px';

/* This is the main nav menu positioned on the top on wide screens */
export const navMenuTopHeight = '64px';

/* This is the main nav menu positioned along the bottom on narrower screens */
export const navMenubottomHeight = '50px';

/* This is the toolbar positioned along the top on smaller devices (it has the logout button and back button) */
export const toolbarHeight = uikitToolbarHeight + 'px';

/* Width of the stages component on tablets and desktops */
export const stagesWidth = '300px';
