import { createGlobalStyle } from 'styled-components';
import { fontFamily, color } from '@aib/design-tokens';

export const GlobalFontFamilyStyles = createGlobalStyle`
   #root {
    font-family: ${fontFamily.regular};
    -webkit-font-smoothing: antialiased;
  }
`;

export const GlobalAppStyles = createGlobalStyle`
  html,
  body,
  #root {
    position: relative;
    height: 100%;
    background-color: ${color.baseWhite};
  }

  #root {
    height: calc(100% + env(safe-area-inset-top));
    padding-bottom: env(safe-area-inset-bottom);
    padding-top: env(safe-area-inset-top);
    box-sizing: border-box;

    /* ? Attempted fix for the issue on Chrome and Samsung where the bottom of the screen was showing part of the top of the screen */
    overflow: hidden;
  }

  body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;

    /*
      The base font-size. All fonts are defined relative to this using rem values
      rem 1 = 16px, rem 1.5 = 24px etc..
    */
    font-size: 16px;

    /* Disables pull-to-refresh but allows overscroll glow effects. */

    /* https://developers.google.com/web/updates/2017/11/overscroll-behavior */
    overscroll-behavior-y: contain;
  }

  #root-reset {
    height: 100%;
    overflow: auto;
  }
`;
