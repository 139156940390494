const origin = 'taskViewReducer';
const FETCH_PERSISTENCE = `${origin}/fetchPersistence`;
const FETCH_PERSISTENCE_SUCCESS = `${origin}/fetchPersistenceSuccess`;
const FETCH_PERSISTENCE_FAILURE = `${origin}/fetchPersistenceFailure`;
const FETCH_TASKS = `${origin}/fetchTasks`;
const FETCH_TASKS_SUCCESS = `${origin}/fetchTasksSuccess`;
const FETCH_TASKS_FAILURE = `${origin}/fetchTasksFailure`;
const UPDATE_TASKS = `${origin}/updateTasks`;
const SET_STAGE_PTR = `${origin}/setStagePtr`;

export {
  FETCH_PERSISTENCE,
  FETCH_PERSISTENCE_SUCCESS,
  FETCH_PERSISTENCE_FAILURE,
  FETCH_TASKS,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_FAILURE,
  UPDATE_TASKS,
  SET_STAGE_PTR,
};
