import React from 'react';
import Modal from '@aib/uikit-react/dist/Animations/Modal/Modal';
import InfoPopupView from '@aib/uikit-react/dist/Content/InfoPopupView/InfoPopupView';
import PropTypes from 'prop-types';

function CiamError({ showModal, onClick }) {
  return (
    <Modal open={showModal}>
      <InfoPopupView
        title={'There is a mismatch with the information you gave us'}
        body={['Please try again or contact your Homes Advisor.']}
        isWarning
        buttonText={'Try again'}
        onClick={onClick}
        hasCloseButton={false}
      />
    </Modal>
  );
}

CiamError.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CiamError;
