import * as applicationDetailsTypes from '../applicationDetails/applicationDetails.types';
import * as tasksTypes from '../tasks/tasks.types';
import * as persistentStateTypes from '../persistentState/persistentState.types';
import { createLoadingReducer } from './showLoading';

/* Define which actions trigger the full screen spinner */
const actions = [
  [
    applicationDetailsTypes.FETCH_APPLICATION_DETAILS,
    applicationDetailsTypes.FETCH_APPLICATION_DETAILS_SUCCESS,
    applicationDetailsTypes.FETCH_APPLICATION_DETAILS_FAILURE,
  ],
  [
    tasksTypes.FETCH_TASKS,
    tasksTypes.FETCH_TASKS_SUCCESS,
    tasksTypes.FETCH_TASKS_FAILURE,
  ],
  [
    persistentStateTypes.STATE_GET,
    persistentStateTypes.STATE_GET_SUCCESS,
    persistentStateTypes.STATE_GET_FAILURE,
  ],
];

const reducerName = 'fullScreenLoading';

const fullScreenLoadingReducer = createLoadingReducer(actions, reducerName);

/* Selectors */
const showFullScreenLoading = (state = { [reducerName + 'Reducer']: [] }) => {
  return state[reducerName + 'Reducer'].length > 0;
};

export { showFullScreenLoading, fullScreenLoadingReducer };
