/**
 * Settings for switching things on/off in app
 * For safety, all settings will default to false
 * if the setting does not exist (which could be
 * the case if this was a separate service)
 */

const map = new Map([
  // Use applicant's first names in intro, doc upload and in toolbar?
  ['USE_APPLICANT_FIRSTNAMES', true],
  // Replace MAS Document Names with user friendly alternatives
  ['REPLACE_DOC_TITLES', true],
  // Remove the upload document feature on the following task statuses:
  [
    'READ_ONLY_TASK_STATUSES',
    [
      32003, // Not Required status
      32004, // Verified status
    ],
  ],
  // Tasks sometimes show a colored status lozenge.
  // If you don't want to see this, add the task status here:
  [
    'HIDDEN_TASK_STATUSES',
    [
      32002, // To-do status
      32005, // Re-issued
      32004, // Verified
    ],
  ],
  // Tasks that are 'to-do' display a circle (with a tick when complete).
  // Tasks that have this UI feature are listed below:
  [
    'TODO_TASK_STATUSES',
    [
      1, // Started
      10000, // Pending
      32001, // Please review
      32005, // Re-issued
      32004, // Verified
      32002, // To-do
    ],
  ],
  // Tasks that are 'complete' display a green tick and you cannot
  // upload docs to them.
  // Tasks that have this UI feature are listed below:
  [
    'COMPLETED_TASK_STATUSES',
    [
      32004, // Verified
      32003, // Not Required
    ],
  ],
  ['VALID_DOC_TYPES', ['pdf', 'png', 'jpeg', 'jpg', 'gif', 'bmp']],
  ['MAX_DOC_SIZE', 10],

  // Frequency at which we save a message to the persistent state when typing:
  ['SAVE_MESSAGE_FREQUENCY', 3000],

  /*
   * Auto logout warning: in seconds. This is the countdown on the
   * auto logout dialog counting down until the app kicks you out
   */
  ['AUTO_LOGOUT_WARNING_TIME', 30],

  /*
   * Session timeout: in minutes. Change it to small number(ex:1) to
   * mimic auto logout behaviour.
   */
  ['SESSION_TIMEOUT', 4.2],

  /*
   * Keep alive frequency: in seconds. Change it to small number(ex:10) to
   * verify keep alive behaviour. This is the frequency of the app checking
   * to see if it should send a keep alive to the backend
   */
  ['KEEP_ALIVE_FREQUENCY', 300],
  ['MESSAGES.MAX_SUBJECT_LENGTH', 40],
  ['MESSAGES.MAX_MESSAGE_LENGTH', 1000],
]);

export default map;
