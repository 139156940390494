import React from 'react';
import { createMiddleware, createMetaReducer } from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';

// saga
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

// router
import { routerMiddleware } from 'connected-react-router';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { reducer, rootSaga } from './store/index';
import eventDefinitionsMap from './analytics';
import apiSequencerMiddleware from './store/apiSequencerMiddleware';
import persistentStateMiddleware from './store/persistentStateMiddleware';
import openBankingMiddleware from './store/openBanking/openBankingMiddleware';
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();
const gtm = GoogleTagManager(); // eslint-disable-line
const gtmMiddleware = createMiddleware(eventDefinitionsMap, gtm);
const gtmMetaReducer = createMetaReducer(eventDefinitionsMap, gtm);
const middleware = [
  routerMiddleware(history),
  sagaMiddleware,
  apiSequencerMiddleware,
  persistentStateMiddleware,
  gtmMiddleware,
  openBankingMiddleware,
];
const enhancer = composeEnhancers(applyMiddleware(...middleware));
const store = createStore(
  gtmMetaReducer(reducer(history)),
  undefined,
  enhancer
);

sagaMiddleware.run(rootSaga);

export default (WrappedComponent) => {
  return class WithRedux extends React.Component {
    render() {
      return (
        <Provider store={store}>
          <Router history={history}>
            <WrappedComponent {...this.props} />
          </Router>
        </Provider>
      );
    }
  };
};
