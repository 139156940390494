import * as types from './docUpload.types';

const addFile = (data) => ({
  type: types.FILE_ADDED,
  data,
});

const removeFile = (data) => ({
  type: types.FILE_REMOVED,
  data,
});

const submitDocs = (conditionReferenceId) => ({
  type: types.SUBMIT_DOCS,
  conditionReferenceId,
});

const submitDoc = (file) => ({
  type: types.SUBMIT_DOC,
  file,
});

const submitDocsSuccess = (conditionReferenceId) => ({
  type: types.SUBMIT_DOCS_SUCCESS,
  conditionReferenceId,
});

const switchTabs = (switchTo) => ({
  type: types.TABS_SWITCHED,
  data: {
    value: switchTo,
  },
});

const uploadFailure = (uniqueDocumentId, conditionReferenceId, errorCode) => ({
  type: types.UPLOAD_FAILURE,
  data: {
    uniqueDocumentId,
    conditionReferenceId,
    errorCode,
  },
});

const uploadsInProgress = (inProgress, conditionReferenceId) => ({
  type: types.UPLOADS_IN_PROGRESS,
  data: {
    inProgress,
    conditionReferenceId,
  },
});

const uploadStarted = (uniqueDocumentId, conditionReferenceId) => ({
  type: types.UPLOAD_STARTED,
  data: {
    uniqueDocumentId,
    conditionReferenceId,
  },
});

const uploadProgress = (uniqueDocumentId, progress, conditionReferenceId) => ({
  type: types.UPLOAD_PROGRESS,
  data: {
    uniqueDocumentId,
    progress,
    conditionReferenceId,
  },
});

const uploadSuccess = (uniqueDocumentId, conditionReferenceId) => ({
  type: types.UPLOAD_SUCCESS,
  data: {
    uniqueDocumentId,
    conditionReferenceId,
  },
});

export {
  addFile,
  removeFile,
  submitDocs,
  submitDoc,
  submitDocsSuccess,
  switchTabs,
  uploadStarted,
  uploadProgress,
  uploadSuccess,
  uploadFailure,
  uploadsInProgress,
};
