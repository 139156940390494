import { detect } from 'detect-browser';

const browserDetails = detect();

const UNSUPPORTED_BROWSERS = ['ie'];

const PARTIAL_SUPPORT_VERSIONS = {
  chrome: 62,
  safari: 10,
  'ios-webview': 10,
  firefox: 61,
  edge: 14,
  opera: 48,
  samsung: 7,
};

const FULL_SUPPORT_VERSIONS = {
  chrome: 62,
  safari: 10,
  'ios-webview': 10,
  firefox: 61,
  edge: 16,
  opera: 48,
  samsung: 7,
};

export default function () {
  const { name, version } = browserDetails;
  const majorVersion = version?.split('.')[0];

  if (UNSUPPORTED_BROWSERS.includes(name)) {
    return 'BROWSER_NOT_SUPPORTED';
  }

  if (Number(majorVersion) < PARTIAL_SUPPORT_VERSIONS[name]) {
    return 'BROWSER_VERSION_NOT_SUPPORTED';
  }

  if (Number(majorVersion) < FULL_SUPPORT_VERSIONS[name]) {
    return 'BROWSER_VERSION_PARTIALLY_SUPPORTED';
  }

  return null;
}
