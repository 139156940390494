import React from 'react';
import LogInOrRegister from '@aib/mcx-shared-fe/dist/pages/LogInOrRegisterPage/LogInOrRegister';
import PropTypes from 'prop-types';

const LogInOrRegisterPage = ({ navigateToTasks }) => {
  return <LogInOrRegister handleClick={navigateToTasks} />;
};

LogInOrRegisterPage.propTypes = {
  navigateToTasks: PropTypes.func,
};

export default LogInOrRegisterPage;
