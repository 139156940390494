import {
  OB_REDIRECT_URL,
  OB_CONSENT_ID,
  OB_CONDITION_REF_ID,
  CURRENT_APPLICANT,
} from './openBanking.sagas';
import { PARSE_QUERYSTRING } from './openBanking.types';
import { interceptRedirect, closeAccordion } from './openBanking.actions';
import { push } from 'connected-react-router';
import { switchTabs } from '../docUpload/docUpload.actions';

const openBankingMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case PARSE_QUERYSTRING: {
      next(action);
      const searchParams = new URLSearchParams(location.search);
      const status = searchParams.getAll('status').pop();
      const result = searchParams.getAll('result').pop();
      const taskId = searchParams.getAll('connection_task_id').pop();
      const consId = localStorage?.getItem(OB_CONSENT_ID);
      const condRefId = Number(localStorage?.getItem(OB_CONDITION_REF_ID));
      const curApplicant = localStorage?.getItem(CURRENT_APPLICANT);
      const redirectUrl = localStorage?.getItem(OB_REDIRECT_URL);

      if (
        status &&
        consId &&
        taskId &&
        condRefId &&
        curApplicant &&
        redirectUrl
      ) {
        store.dispatch(
          interceptRedirect(status, result, taskId, condRefId, consId)
        );
        store.dispatch(switchTabs(curApplicant));
        store.dispatch(push(redirectUrl || location.pathname));
        store.dispatch(closeAccordion());
      }

      localStorage?.removeItem(OB_REDIRECT_URL);
      localStorage?.removeItem(OB_CONSENT_ID);
      localStorage?.removeItem(OB_CONDITION_REF_ID);
      localStorage?.removeItem(CURRENT_APPLICANT);
      break;
    }
    default:
      next(action);
  }
};

export default openBankingMiddleware;
