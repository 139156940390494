const errorCodeTypes = {
  CONNECTION_UNAVAILABLE: 'connection_unavailable',
};

const redirectResultsTypes = {
  AWAITING_AUTHORISATION: 'awaiting_authorisation',
  ACCESS_DENIED: 'auth_denied',
  AUTH_INVALID: 'auth_invalid',
  AUTH_EXPIRED: 'auth_expired',
  INTERNAL_ERROR: 'internal_error',
  PROVIDER_FAILURE: 'provider_failure',
};

export const redirectStatusTypes = {
  SUCCESS: 'success',
  FAILED: 'failed',
};
export const errorCodes = { ...errorCodeTypes, ...redirectResultsTypes };
