import styled from 'styled-components';
import { color } from '@aib/design-tokens';

export const InputWrapper = styled.div`
  margin-top: 50px;
  width: 300px;

  label {
    padding-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: ${(props) => (props.hasValue ? color.corePurple100 : color.grey07)};
  }

  input {
    border: 1px solid
      ${(props) => (props.hasValue ? color.corePurple100 : color.grey07)};
  }

  input:focus-within {
    border: 1px solid
      ${(props) => (props.hasValue ? color.corePurple100 : color.grey07)};
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: list;
  gap: 6rem;
`;
