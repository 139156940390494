import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import selectDialogConfig from '../../../store/dialog/dialog.selectors';
import DocUploadSuccessDialog from './DocUploadSuccessDialog';
import DocUploadErrorDialog from './DocUploadErrorDialog';
import LogoutDialog from './LogoutDialog';
import AutoLogoutDialog from './AutoLogoutDialog';
import DocUploadFilesTooBigDialog from './DocUploadFilesTooBigDialog';
import DocUploadFileTypeNotAllowedDialog from './DocUploadFileTypeNotAllowedDialog';
import DocListErrorDialog from './DocListErrorDialog';
import OpenBankingErrorDialog from './OpenBankingErrorDialog';
import OpenBankingPdfGenerated from './OpenBankingPdfGenerated';
import MyMortgageAccessModal from './MyMortgageAccessModal';

const DIALOG_COMPONENTS = {
  DOC_UPLOAD_SUCCESS: DocUploadSuccessDialog,
  DOC_UPLOAD_ERROR: DocUploadErrorDialog,
  DOC_UPLOAD_FILES_TOO_BIG: DocUploadFilesTooBigDialog,
  DOC_UPLOAD_FILE_TYPE_NOT_ALLOWED: DocUploadFileTypeNotAllowedDialog,
  LOGOUT_DIALOG: LogoutDialog,
  AUTO_LOGOUT_DIALOG: AutoLogoutDialog,
  DOC_LIST_ERROR: DocListErrorDialog,
  OPEN_BANKING_PDF_GENERATED: OpenBankingPdfGenerated,
  OPEN_BANKING_ERROR: OpenBankingErrorDialog,
  MY_MORTGAGE_ACCESS: MyMortgageAccessModal,
};

export const DIALOGS = Object.keys(DIALOG_COMPONENTS).reduce((prev, curr) => {
  prev[curr] = curr;
  return prev;
}, {});

const RootDialog = ({ dialogConfig }) => {
  if (!dialogConfig) {
    return null;
  }
  const { dialogType, dialogProps } = dialogConfig;

  const SpecificDialog = DIALOG_COMPONENTS[dialogType];
  return <SpecificDialog {...dialogProps} />;
};

RootDialog.propTypes = {
  dialogConfig: PropTypes.object,
};

const mapStateToProps = (state) => ({
  dialogConfig: selectDialogConfig(state),
});

export default connect(mapStateToProps, null)(RootDialog);
