import { call, put, takeEvery, all, take, select } from 'redux-saga/effects';
import { fetchProviders, fetchProviderUrl, postStatement } from '../api';
import { showDialog } from '../dialog/dialog.actions';
import * as types from './openBanking.types';
import { STATE_GET_SUCCESS } from '../persistentState/persistentState.types';
import { FETCH_TASKS_SUCCESS } from '../tasks/tasks.types';
import { errorCodes, redirectStatusTypes } from './openBanking.statusCodes';
import {
  fetchProviderUrlFailure,
  fetchProviderUrlSuccess,
  showModal,
  startPdfGeneration,
  storeProviders,
} from './openBanking.actions';
import { updateTaskState } from '../persistentState/persistentState.actions';
import { getCurrentApplicant } from '../docUpload/docUpload.selectors';
import { tagCustomEvent } from '../../analytics/eventDefinitions';
import { DIALOGS } from '../../components/Communications/Dialog/RootDialog';
import { SHOW_DIALOG } from '../dialog/dialog.types';

const { OPEN_BANKING_ERROR } = DIALOGS;
export const OB_REDIRECT_URL = 'ob_redirect_url';
export const OB_CONSENT_ID = 'ob_consent_id';
export const OB_CONDITION_REF_ID = 'ob_condition_ref_id';
export const CURRENT_APPLICANT = 'current_applicant';

const showErrorDialog = (errorCode, errorSource) =>
  put(
    showDialog(OPEN_BANKING_ERROR, {
      errorCode,
      errorSource,
    })
  );

export function* fetchProvidersFn() {
  try {
    const response = yield call(fetchProviders);

    if (response.ok) {
      yield put(storeProviders(response));
      yield put(showModal());
    } else {
      yield showErrorDialog(
        errorCodes.CONNECTION_UNAVAILABLE,
        types.FETCH_PROVIDERS_FAILURE
      );
    }
  } catch (e) {
    yield showErrorDialog(
      errorCodes.CONNECTION_UNAVAILABLE,
      types.FETCH_PROVIDERS_FAILURE
    );
  }
}

export function* fetchProviderUrlFn(data) {
  const { cif, provider, redirectUrl, documentTypeId, conditionRefId } = data;
  try {
    const response = yield call(
      fetchProviderUrl,
      cif,
      provider,
      documentTypeId,
      conditionRefId
    );
    if (response.ok) {
      yield put(fetchProviderUrlSuccess());
      const currentApplicant = yield select(getCurrentApplicant);
      localStorage?.setItem(OB_REDIRECT_URL, redirectUrl);
      localStorage?.setItem(OB_CONSENT_ID, response?.resp?.consentId);
      localStorage?.setItem(OB_CONDITION_REF_ID, conditionRefId);
      localStorage?.setItem(CURRENT_APPLICANT, currentApplicant);
      location.href = response?.resp?.authUrl;
    } else {
      yield put(fetchProviderUrlFailure(response));
      yield showErrorDialog(
        errorCodes.CONNECTION_UNAVAILABLE,
        types.FETCH_PROVIDER_URL_FAILURE
      );
    }
  } catch (e) {
    yield put(fetchProviderUrlFailure(e));
    yield showErrorDialog(
      errorCodes.CONNECTION_UNAVAILABLE,
      types.FETCH_PROVIDER_URL_FAILURE
    );
  }
}

export function* interceptRedirectFn({
  status,
  result,
  taskId,
  conditionRefId,
  consentId,
}) {
  let error = null;

  if (status === redirectStatusTypes.FAILED) {
    switch (result) {
      case errorCodes.ACCESS_DENIED:
        error = errorCodes.ACCESS_DENIED;
        break;
      default:
        error = errorCodes.INTERNAL_ERROR;
        break;
    }
  }

  if (error) {
    yield showErrorDialog(
      error,
      `${types.INTERCEPT_REDIRECT_FAILURE}-${result}`
    );
    yield call(postStatement, consentId, false, taskId);
  } else
    try {
      const response = yield call(postStatement, consentId, true, taskId);
      if (response.ok) {
        yield put(showDialog('OPEN_BANKING_PDF_GENERATED'));
        yield put(startPdfGeneration(conditionRefId));
      } else {
        yield showErrorDialog(
          errorCodes.INTERNAL_ERROR,
          types.START_PDF_GENERATION_FAILURE
        );
      }
    } catch (e) {
      yield showErrorDialog(
        errorCodes.INTERNAL_ERROR,
        types.START_PDF_GENERATION_FAILURE
      );
    }
}

export function* fetchProvidersSaga() {
  yield takeEvery(types.START_OB, fetchProvidersFn);
}

export function* fetchProviderUrlSaga() {
  yield takeEvery(types.FETCH_PROVIDER_URL, fetchProviderUrlFn);
}

export function* interceptRedirectSaga() {
  yield takeEvery(types.INTERCEPT_REDIRECT, interceptRedirectFn);
}

export function* interceptErrorsSaga() {
  let conditionIdsStatuses;
  let allTasks;
  while (true) {
    const result = yield take([FETCH_TASKS_SUCCESS, STATE_GET_SUCCESS]);
    switch (result.type) {
      case FETCH_TASKS_SUCCESS:
        allTasks = result.data.reduce(
          (prev, curr) =>
            prev.concat(
              curr.tasks.map((task) => ({
                ...task,
                categoryId: curr.categoryId,
              }))
            ),
          []
        );
        break;
      case STATE_GET_SUCCESS:
        conditionIdsStatuses = result.data?.conditionIdsWithDocsUploaded;
        break;
    }
    if (conditionIdsStatuses && allTasks) {
      const obTaskError = allTasks.find((task) => {
        return (
          task.isOpenBankingCondition &&
          conditionIdsStatuses?.[task.conditionReferenceId.toString()] === false
        );
      });

      if (obTaskError) {
        yield put(updateTaskState(obTaskError.conditionReferenceId));
        yield showErrorDialog(
          errorCodes.INTERNAL_ERROR,
          types.PDF_GENERATION_FAILURE
        );
      }
    }
  }
}

export function* setTaskToPendingSaga() {
  let tasksAvailable = false;
  let conditionReferenceId;
  while (true) {
    const result = yield take([STATE_GET_SUCCESS, types.START_PDF_GENERATION]);
    switch (result.type) {
      case STATE_GET_SUCCESS:
        tasksAvailable = true;
        break;
      case types.START_PDF_GENERATION:
        conditionReferenceId = result.conditionReferenceId;
        break;
    }
    if (tasksAvailable && conditionReferenceId) {
      yield put(updateTaskState(conditionReferenceId));
    }
  }
}

export function* tagVirtualPageViewsSaga() {
  const tagOpenBanking = tagCustomEvent('open-banking-virtual-page-view');
  while (true) {
    const result = yield take([
      types.SHOW_MODAL,
      types.NEXT_STEP,
      types.FETCH_PROVIDER_URL_SUCCESS,
      types.START_PDF_GENERATION,
    ]);
    switch (result.type) {
      case types.SHOW_MODAL:
        tagOpenBanking({ step: 'providers-select' });
        break;
      case types.NEXT_STEP:
        tagOpenBanking({ step: 'consent-page' });
        break;
      case types.FETCH_PROVIDER_URL_SUCCESS:
        tagOpenBanking({ step: 'redirect-to-provider-page' });
        break;
      case types.START_PDF_GENERATION:
        tagOpenBanking({ step: 'pdf-queued-successfully' });
        break;
    }
  }
}

export function* tagOpenBankingErrorsSaga() {
  const tagOpenBanking = tagCustomEvent('open-banking-errors');
  while (true) {
    const result = yield take([SHOW_DIALOG]);
    const { dialogType, dialogProps } = result;
    if (dialogType === OPEN_BANKING_ERROR)
      tagOpenBanking({ error: dialogProps.errorSource });
  }
}

export default function* root() {
  yield all({
    fetchProvidersSaga: call(fetchProvidersSaga),
    fetchProviderUrlSaga: call(fetchProviderUrlSaga),
    interceptRedirectSaga: call(interceptRedirectSaga),
    interceptErrors: call(interceptErrorsSaga),
    setTaskToPending: call(setTaskToPendingSaga),
    tagVirtualPageViews: call(tagVirtualPageViewsSaga),
    tagOpenBankingErrors: call(tagOpenBankingErrorsSaga),
  });
}
