import * as types from './ciam.types';

export const connectApplication = (masApplicationId, oldAppId) => ({
  type: types.CONNECT_APPLICATION,
  masApplicationId,
  oldAppId,
});

export const connectApplicationSuccess = () => ({
  type: types.CONNECT_APPLICATION_SUCCESS,
});

export const connectApplicationFailure = (err) => ({
  type: types.CONNECT_APPLICATION_FAILURE,
  err,
});

export const resetConnectApplicationError = () => ({
  type: types.RESET_CONNECT_APPLICATION_ERROR,
});

export const fetchProfileData = () => ({
  type: types.FETCH_PROFILE_DATA,
});

export const fetchProfileDataSuccess = (profile) => ({
  type: types.FETCH_PROFILE_DATA_SUCCESS,
  profile,
});

export const fetchProfileDataFailure = (profile) => ({
  type: types.FETCH_PROFILE_DATA_FAILURE,
  profile,
});
