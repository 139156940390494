import Utils from '../../lib/utils';
import * as types from './dialog.types';

const initialState = Utils.getStateTemplate('dialog');

const dialogReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SHOW_DIALOG:
      return [
        ...state,
        {
          dialogType: action.dialogType,
          dialogProps: action.dialogProps,
        },
      ];
    case types.HIDE_DIALOG:
      return state.slice(1);
    default:
      return state;
  }
};

export default dialogReducer;
