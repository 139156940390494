import React from 'react';
import PropTypes from 'prop-types';
import * as S from './SplashPage.styled';
import { Link as ReactRouterLink } from 'react-router-dom';
import {
  MCX_CALCULATOR,
  SWITCHER_CALC,
  APPOINTMENT_BOOKING,
  DIGITAL_MORTGAGE_UNPROTECTED,
  DASHBOARD,
} from './../../../lib/urls';

const DebugLinks = () => {
  return (
    <>
      <S.DebugLinksSwitch type="checkbox" id="dev-tools-cb" />
      <S.DebugLinksWrapper>
        <S.DebugLinksLabel htmlFor="dev-tools-cb">
          Dev Tools <S.DebugLinksChevron />
        </S.DebugLinksLabel>
        <h3>Other apps</h3>
        <ul>
          <li>
            <ReactRouterLink to={DIGITAL_MORTGAGE_UNPROTECTED}>
              Digital Mortgage
            </ReactRouterLink>
          </li>
          <li>
            <ReactRouterLink to={SWITCHER_CALC}>Switcher</ReactRouterLink>
          </li>
          <li>
            <ReactRouterLink to={MCX_CALCULATOR}>Calculator</ReactRouterLink>
          </li>
          <li>
            <ReactRouterLink to={APPOINTMENT_BOOKING + '-new'}>
              Appointment Booking (new)
            </ReactRouterLink>
          </li>
          <li>
            <ReactRouterLink to={APPOINTMENT_BOOKING + '-change'}>
              Appointment Booking (change)
            </ReactRouterLink>
          </li>
          <li>
            <ReactRouterLink to={DASHBOARD}>Dashboard</ReactRouterLink>
          </li>
        </ul>
      </S.DebugLinksWrapper>
    </>
  );
};

export default DebugLinks;

const InfoIcon = ({ title }) => {
  const iconStyles = {
    display: 'inline-block',
    width: '16px',
    position: 'relative',
  };
  return (
    <div style={iconStyles} title={title}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 24 24">
        <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-.001 5.75c.69 0 1.251.56 1.251 1.25s-.561 1.25-1.251 1.25-1.249-.56-1.249-1.25.559-1.25 1.249-1.25zm2.001 12.25h-4v-1c.484-.179 1-.201 1-.735v-4.467c0-.534-.516-.618-1-.797v-1h3v6.265c0 .535.517.558 1 .735v.999z" />
      </svg>
    </div>
  );
};

InfoIcon.propTypes = {
  title: PropTypes.string,
};
