import { call, put, takeLatest } from 'redux-saga/effects';
import {
  fetchApplicationData,
  setApplicationId,
  setCustomerType,
  setChannelDescription,
  fetchDashboardData,
} from '../api';
import { reloadSplashPage } from '../session';
import * as types from './applicationDetails.types';

function* getApplicationDetails() {
  try {
    const response = yield call(fetchApplicationData);
    if (response.ok) {
      const customerDetails = response.resp.customerTypeDetails;
      const channelDetails = response.resp.channel;
      // Pass the applicationId, customerTypeDescriptiom and channelName to the api for use on (almost) every future request.
      setApplicationId(response.resp.applicationId);
      setCustomerType(customerDetails?.customerTypeDescription);
      setChannelDescription(channelDetails?.channelName);
      yield put({
        type: types.FETCH_APPLICATION_DETAILS_SUCCESS,
        data: response.resp,
      });
    } else {
      yield put({
        type: types.FETCH_APPLICATION_DETAILS_FAILURE,
        err: response.err,
      });
      reloadSplashPage();
    }
  } catch (e) {
    console.error('Error fetching applicationDetails', e);
    yield put({ type: types.FETCH_APPLICATION_DETAILS_FAILURE, err: e });
    reloadSplashPage();
  }
}

function* fetchExistingApplicationDetails() {
  try {
    const response = yield call(fetchDashboardData);
    if (response.ok) {
      yield put({
        type: types.FETCH_EXISTING_APPLICATION_DETAILS_SUCCESS,
        data: response.resp,
      });
    } else {
      yield put({
        type: types.FETCH_EXISTING_APPLICATION_DETAILS_FAILURE,
        err: response.err,
      });
    }
  } catch (e) {
    console.error('Error fetching applicationDetails', e);
    yield put({
      type: types.FETCH_EXISTING_APPLICATION_DETAILS_FAILURE,
      err: e,
    });
  }
}

// Side Effects/Saga
function* applicationDetailsSaga() {
  yield takeLatest(types.FETCH_APPLICATION_DETAILS, getApplicationDetails);
  yield takeLatest(
    types.FETCH_EXISTING_APPLICATION_DETAILS,
    fetchExistingApplicationDetails
  );
}

export {
  getApplicationDetails,
  applicationDetailsSaga,
  fetchExistingApplicationDetails,
};
