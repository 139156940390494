import * as types from './persistentState.types';

const hideWelcomeMessage = (userId) => ({
  type: types.HIDE_WELCOME_MESSAGE,
  userId,
});

const hideInfoMessage = (userId) => ({
  type: types.HIDE_INFO_MESSAGE,
  userId,
});

const saveMessageAsDraft = (key, text) => ({
  type: types.SAVE_MESSAGE_AS_DRAFT,
  data: {
    key,
    text,
  },
});

const deleteDraftMessage = (key) => ({
  type: types.SAVE_MESSAGE_AS_DRAFT,
  data: {
    key,
  },
});

const fetchPersistentState = () => ({
  type: types.STATE_GET,
});

const putPersistentState = (data) => ({
  type: types.STATE_PUT,
  data,
});

const updateTaskState = (conditionRefId) => ({
  type: types.TASK_STATE_UPDATE,
  conditionRefId,
});

const storeMasState = (conditionReferenceId) => ({
  type: types.STATE_STORE,
  data: { conditionReferenceId },
});

const syncStateWithMas = () => ({
  type: types.SYNC_STATE_WITH_MAS,
});

export {
  hideWelcomeMessage,
  hideInfoMessage,
  saveMessageAsDraft,
  deleteDraftMessage,
  fetchPersistentState,
  putPersistentState,
  updateTaskState,
  storeMasState,
  syncStateWithMas,
};
