/**
 * @Public
 * Template Method to instantiate configuration
 * for the Dialog with initialised defaults
 */
export default ({
  heading = '',
  content = '',
  accept = 'Yes',
  cancel = 'No Thanks',
  visible = false,
}) => ({
  heading,
  content,
  accept,
  cancel,
  visible,
});
