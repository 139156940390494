import * as types from './tasks.types';

function fetchTasksFromService() {
  return {
    type: types.FETCH_TASKS,
  };
}

function updateTasks(data) {
  return {
    type: types.UPDATE_TASKS,
    data,
  };
}

function setStagePtr(data) {
  return {
    type: types.SET_STAGE_PTR,
    data,
  };
}

export { fetchTasksFromService, updateTasks, setStagePtr };
