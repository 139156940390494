import { assoc, assocPath, append, clone, reject } from 'ramda';

import * as types from './messages.types';
import * as select from './messages.selectors';
import Utils from '../../lib/utils';

const initialState = Utils.getStateTemplate('messages');

const messagesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FETCH_THREADS_SUCCESS:
      return assoc('threads', [...action.data], state);
    case types.FETCH_THREADS_FAILURE:
      return state;
    case types.FETCH_THREAD_SUCCESS:
      return {
        ...state,
        thread: {
          [action.data.threadId]: action.data.threadMessages,
        },
        currentThread: action.data.threadId,
        areMessagesLoading: false,
      };
    case types.FETCH_THREAD_IN_PROGRESS:
      return { ...state, areMessagesLoading: true };
    case types.FETCH_THREAD_FAILURE:
      return { ...state, areMessagesLoading: false };
    case types.SHOW_CREATE_MESSAGE:
      return { ...state, isCreateMessageVisible: true };
    case types.HIDE_CREATE_MESSAGE:
      return { ...state, isCreateMessageVisible: false };
    case types.RESET_CURRENT_THREAD:
      return {
        ...state,
        thread: initialState.thread,
        currentThread: initialState.currentThread,
      };
    case types.UNSAVED_THREAD_REPLY:
      return assocPath(
        ['thread', state.currentThread],
        append(action.unsavedData, select.getThreadList(state)),
        state
      );
    case types.SUBMIT_THREAD_REPLY_SUCCESS: {
      // get the unsaved message
      const message = select.getMessageById(
        state.currentThread,
        action.unsavedId,
        state
      );
      const filteredState = clone(state);
      const messageToFilter = (obj) => {
        return message.id === obj.id;
      };
      // remove the unsaved message from state
      filteredState.thread[state.currentThread] = reject(
        messageToFilter,
        filteredState.thread[state.currentThread],
        filteredState
      );

      // add the saved version of the message to state
      return assocPath(
        ['thread', filteredState.currentThread],
        append(action.data, select.getThreadList(filteredState)),
        filteredState
      );
    }
    case types.SUBMIT_THREAD_REPLY_FAILURE: {
      const failedState = clone(state);
      const failedMessage = select.getMessageById(
        state.currentThread,
        action.unsavedId,
        failedState
      );

      failedMessage.failed = true;
      return failedState;
    }
    case types.DELETE_MESSAGE: {
      const deleteState = clone(state);
      const deleteMessage = select.getMessageById(
        state.currentThread,
        action.Id,
        deleteState
      );

      deleteState.thread[state.currentThread].splice(
        deleteState.thread[state.currentThread].indexOf(deleteMessage)
      );
      return deleteState;
    }
    case types.POST_THREAD:
      return { ...state, isMessageSending: true };
    case types.POST_THREAD_SUCCESS:
      return { ...state, isMessageSending: false };
    case types.POST_THREAD_FAILURE:
      // console.error(action);
      return { ...state, isMessageSending: false };
    case types.UPDATE_THREAD_MODIFIED_DATE: {
      const threadState = clone(state);
      const thread = select.getThreadByThreadId(
        threadState,
        threadState.currentThread
      );
      thread.dateModified = Math.round(Date.now() / 1000).toString();
      return threadState;
    }
    default:
      return state;
  }
};

export default messagesReducer;
