import { path } from 'ramda';
import {
  declinedStatuses,
  completedStatuses,
  notProceededWithStatuses,
} from '../../lib/maps/masApplicationStatusIds';
import customerTypeIds from '../../lib/maps/customerTypeIds.json';

const canProceed = (appStatusId) =>
  !isDeclinedApplication(appStatusId) &&
  !isNotProceededWithApplication(appStatusId);

const isNotProceededWithApplication = (appStatusId) =>
  notProceededWithStatuses.includes(Number(appStatusId));

const isDeclinedApplication = (appStatusId) =>
  declinedStatuses.includes(Number(appStatusId));

const getIsCompletedApplication = (appStatusId) =>
  completedStatuses.includes(Number(appStatusId));

const getApplicationCreationDate = (state) =>
  state?.applicationDetailsReducer?.applicationCreationDate;

const getApplicants = (state) =>
  path(['applicationDetailsReducer', 'applicants'], state);

const getIsExpressOrOnline = (state) => {
  const customerTypeId =
    state?.applicationDetailsReducer?.customerTypeDetails?.customerTypeId;
  return (
    customerTypeId === customerTypeIds.EXPRESS ||
    customerTypeId === customerTypeIds.ONLINE
  );
};

const getApplicantDetails = (state, applicant) =>
  path(['applicationDetailsReducer', 'applicants', applicant], state);

export const getTotalLoanAmount = (state) =>
  path(['applicationDetailsReducer', 'loan', 'totalLoanAmount'], state);

export const getTotalDisbursedAmount = (state) =>
  path(
    [
      'applicationDetailsReducer',
      'applicationFulfilmentDetails',
      'totalDisbursedAmount',
    ],
    state
  );

export const getAmountLeftToDisburse = (state) =>
  path(
    [
      'applicationDetailsReducer',
      'applicationFulfilmentDetails',
      'amountLeftToDisburse',
    ],
    state
  );

const getStatusCode = (state) =>
  path(['applicationDetailsReducer', 'statusCode'], state);

const getApplicationId = (state) =>
  path(['applicationDetailsReducer', 'applicationId'], state);

const getLoanSubPurposeId = (state) =>
  path(['applicationDetailsReducer', 'loanSubPurpose', 'id'], state);

const getCifKey = (state) =>
  path(['applicationDetailsReducer', 'cifKey'], state);

const isSoleApplication = (state) => getApplicants(state)?.length === 1;

const getHasExistingApplicationDataFetched = (state) =>
  path(
    ['applicationDetailsReducer', 'hasExistingApplicationDataFetched'],
    state
  );

const getActiveMortgageApplication = (state) =>
  path(['applicationDetailsReducer', 'activeMortgageApplication'], state);

const hasActiveMortgageApplication = (state) =>
  getActiveMortgageApplication(state) !== null;

const getSavedApplication = (state) =>
  path(['applicationDetailsReducer', 'savedMortgageApplication'], state);

const hasSavedApplications = (state) => getSavedApplication(state) !== null;

export {
  canProceed,
  getApplicationCreationDate,
  isNotProceededWithApplication,
  isDeclinedApplication,
  getIsCompletedApplication,
  getApplicants,
  getApplicantDetails,
  getStatusCode,
  getApplicationId,
  getLoanSubPurposeId,
  getIsExpressOrOnline,
  getCifKey,
  isSoleApplication,
  getHasExistingApplicationDataFetched,
  getActiveMortgageApplication,
  hasActiveMortgageApplication,
  getSavedApplication,
  hasSavedApplications,
};
