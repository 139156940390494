import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AutoLogoutConnected from '../AutoLogout/AutoLogoutConnected';
import KeepAliveConnected from '../KeepAlive/KeepAliveConnected';
import withRedux from '../../../withRedux';
import cookieUtils from '../../../lib/cookieUtils';
import { connect } from 'react-redux';
import { hasActiveSession } from '../../../store/appState/appState.selectors';
import RootDialog from '../../Communications/Dialog/RootDialog';
import { getProfileData } from '../../../store/ciam/ciam.selectors';
import { fetchProfileData } from '../../../store/ciam/ciam.actions';

const SessionWrapper = ({
  children,
  hasActiveSession,
  profileData,
  fetchProfileData,
}) => {
  const targetType = process.env.REACT_APP_PROXY_TARGET_TYPE;
  if (targetType === 'JSON_SERVER') {
    cookieUtils.createMockCsrfCookies();
  }

  useEffect(() => {
    if (hasActiveSession && profileData === null) fetchProfileData();
  }, [hasActiveSession, profileData, fetchProfileData]);

  return (
    <>
      <RootDialog />
      {children(hasActiveSession, profileData)}
      <AutoLogoutConnected />
      <KeepAliveConnected autoRedirect={false} />
    </>
  );
};

SessionWrapper.propTypes = {
  children: PropTypes.func,
  hasActiveSession: PropTypes.bool,
  profileData: PropTypes.object,
  fetchProfileData: PropTypes.func,
};

const mapStateToProps = (state) => ({
  hasActiveSession: hasActiveSession(state),
  profileData: getProfileData(state),
});

const mapDispatchToProps = { fetchProfileData };

export default withRedux(
  connect(mapStateToProps, mapDispatchToProps)(SessionWrapper)
);
