import * as docUploadActions from '../store/docUpload/docUpload.types';
import { actionWithNoId } from './eventDefinitions';

const docUploadEventsMap = {
  [docUploadActions.FILE_ADDED]: actionWithNoId('Document Upload'),
  [docUploadActions.FILE_REMOVED]: actionWithNoId('Document Upload'),
  [docUploadActions.SUBMIT_DOCS]: actionWithNoId('Document Upload'),
  [docUploadActions.SUBMIT_DOC]: actionWithNoId('Document Upload'),
  [docUploadActions.UPLOAD_STARTED]: actionWithNoId('Document Upload'),
  [docUploadActions.UPLOAD_SUCCESS]: actionWithNoId('Document Upload'),
  [docUploadActions.UPLOAD_FAILURE]: actionWithNoId('Document Upload'),
};

export default docUploadEventsMap;
