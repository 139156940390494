import { getMcxApiDomain } from '@aib/uikit-react/dist/utils/env';

export const isPre = () => {
  return window.location.hostname === 'mymortgage.aibtest.ie';
};

export const isPrd = () => {
  return window.location.hostname === 'mymortgage.aib.ie';
};

export const isLocalhost = () =>
  Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

export const mcxApiHost = () => {
  return isLocalhost()
    ? window.location.origin
    : `https://${getMcxApiDomain(window.location.host)}`;
};

export const authSitApiHost = () => {
  if (isLocalhost()) return window.location.origin;

  switch (window.location.host) {
    case 'mymortgage.aibtest.ie':
      return 'https://auth.aibtest.ie';
    case 'mymortgage.aib.ie':
      return 'https://auth.aib.ie';
    default:
      return 'https://auth-sit.aibtest.ie';
  }
};
