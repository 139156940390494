import * as types from './tasks.types';
import Utils from '../../lib/utils';

const initialState = Utils.getStateTemplate('tasks');

/** ************ REDUCER ************ */
function taskViewReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_TASKS_SUCCESS: {
      return { ...state, tasks: action.data };
    }
    case types.FETCH_TASKS_FAILURE: {
      return state;
    }
    case types.UPDATE_TASKS: {
      const { data } = action.data;
      const updatedState = data.taskViewReducer;
      return updatedState;
    }
    default:
      return state;
  }
}

export { taskViewReducer };
