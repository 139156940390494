const origin = 'persistentState';
const HIDE_WELCOME_MESSAGE = `${origin}/hideWelcomeMessage`;
const HIDE_INFO_MESSAGE = `${origin}/hideInfoMessage`;
const SAVE_MESSAGE_AS_DRAFT = `${origin}/saveMessageAsDraft`;
const DELETE_DRAFT_MESSAGE = `${origin}/deleteDraftMessage`;
const SET_STATE_TO_PENDING = `${origin}/setStateToPending`;

const TASK_STATE_UPDATE = `${origin}/taskStateUpdate`;
const STATE_STORE = `${origin}/store`;
const SYNC_STATE_WITH_MAS = `${origin}/syncWithMas`;

const STATE_GET = `${origin}/fetch`;
const STATE_GET_SUCCESS = `${origin}/fetchSuccess`;
const STATE_GET_FAILURE = `${origin}/fetchFailure`;
const STATE_PUT = `${origin}/put`;
const STATE_PUT_SUCCESS = `${origin}/putSuccess`;
const STATE_PUT_FAILURE = `${origin}/putFailure`;

export {
  HIDE_WELCOME_MESSAGE,
  HIDE_INFO_MESSAGE,
  SAVE_MESSAGE_AS_DRAFT,
  DELETE_DRAFT_MESSAGE,
  TASK_STATE_UPDATE,
  STATE_STORE,
  STATE_GET,
  STATE_GET_SUCCESS,
  STATE_GET_FAILURE,
  STATE_PUT,
  STATE_PUT_SUCCESS,
  STATE_PUT_FAILURE,
  SET_STATE_TO_PENDING,
  SYNC_STATE_WITH_MAS,
};
