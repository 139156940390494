import { call, put, takeLatest } from 'redux-saga/effects';
import { fetchSystemMaintenanceData } from '../api';
import * as types from './systemMaintenance.types';

function* getSystemMaintenanceDetails() {
  try {
    const response = yield call(fetchSystemMaintenanceData);
    if (response.ok) {
      yield put({
        type: types.FETCH_SYSTEM_MAINTENANCE_DETAILS_SUCCESS,
        data: response.resp,
      });
    }
  } catch (e) {
    console.error('Error fetching systemMaintenance', e);
  }
}

function* systemMaintenanceDetailsSaga() {
  yield takeLatest(
    types.FETCH_SYSTEM_MAINTENANCE_DETAILS,
    getSystemMaintenanceDetails
  );
}

export { getSystemMaintenanceDetails, systemMaintenanceDetailsSaga };
