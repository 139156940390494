import { assoc, compose } from 'ramda';
import * as types from './documents.types';
import Utils from '../../lib/utils';

const initialState = Utils.getStateTemplate('documents');

function documentsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case types.FETCH_DOCUMENTS_SUCCESS: {
      const applicantDocs = action.data.Applicant || [];
      const aibDocs = action.data.AIB || [];
      return compose(
        assoc('applicantDocuments', [...applicantDocs]),
        assoc('aibDocuments', [...aibDocs])
      )(state);
    }
    case types.FETCH_DOCUMENTS:
      return state;
    default:
      return state;
  }
}

export { documentsReducer };
