import { all, call } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { fullScreenLoadingReducer } from './showLoading/fullScreenLoading';
import { backgroundLoadingReducer } from './showLoading/backgroundLoading';
import { snackbarReducer } from './snackbar';
import { logoutSaga } from './session';
import applicationDetailsReducer from './applicationDetails/applicationDetails.reducers';
import * as applicationDetailsSagas from './applicationDetails/applicationDetails.sagas';
import systemMaintenanceReducer from './systemMaintenance/systemMaintenance.reducers';
import * as systemMaintenanceSagas from './systemMaintenance/systemMaintenance.sagas';
import dialogReducer from './dialog/dialog.reducer';
import { taskViewReducer } from './tasks/tasks.reducers';
import * as tasksSagas from './tasks/tasks.sagas';
import * as docUploadSagas from './docUpload/docUpload.sagas';
import docUploadReducer from './docUpload/docUpload.reducers';
import * as messagesSagas from './messages/messages.sagas';
import messagesReducer from './messages/messages.reducers';
import * as documentsSagas from './documents/documents.sagas';
import { documentsReducer } from './documents/documents.reducers';
import navigationReducer from './navigation/navigation.reducers';
import * as navigationSagas from './navigation/navigation.sagas';
import persistentStateReducer from './persistentState/persistentState.reducers';
import * as persistentStateSagas from './persistentState/persistentState.sagas';
import openBankingReducer from './openBanking/openBanking.reducers';
import openBankingSagas from './openBanking/openBanking.sagas';
import ciamSagas from './ciam/ciam.sagas';
import ciamReducer from './ciam/ciam.reducers';
import appState from './appState/appState.reducer';

export function* rootSaga() {
  yield all({
    logout: call(logoutSaga),
    applicationDetails: call(applicationDetailsSagas.applicationDetailsSaga),
    systemMaintenance: call(
      systemMaintenanceSagas.systemMaintenanceDetailsSaga
    ),
    docUpload: call(docUploadSagas.submitDocsSaga),
    docByFileUpload: call(docUploadSagas.submitDocSaga),
    tasks: call(tasksSagas.tasksSaga),
    threads: call(messagesSagas.threadsSaga),
    thread: call(messagesSagas.threadSaga),
    threadReply: call(messagesSagas.submitReplySaga),
    documents: call(documentsSagas.documentsSaga),
    postThread: call(messagesSagas.postThreadSaga),
    navigationSagas: call(navigationSagas.getNavNotifications),
    fetchState: call(persistentStateSagas.fetchPersistentStateSaga),
    putState: call(persistentStateSagas.putPersistentStateSaga),
    markAsReadSaga: call(messagesSagas.putMarkThreadReadSaga),
    openBankingSagas: call(openBankingSagas),
    updateTaskStateSaga: call(persistentStateSagas.updateTaskStateSaga),
    ciamSagas: call(ciamSagas),
  });
}

export const reducer = (history) =>
  combineReducers({
    fullScreenLoadingReducer,
    backgroundLoadingReducer,
    dialogReducer,
    snackbarReducer,
    applicationDetailsReducer,
    systemMaintenanceReducer,
    taskViewReducer,
    docUploadReducer,
    messagesReducer,
    documentsReducer,
    navigationReducer,
    router: connectRouter(history),
    persistentStateReducer,
    openBankingReducer,
    ciamReducer,
    appState,
  });
