import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideDialog } from '../../../store/dialog/dialog.actions';
import AlertPopup from '@aib/uikit-react/dist/Content/AlertPopup/AlertPopup';
import dialogConfigFactory from './dialogConfigFactory';
import langCommon from '../../lang.common.json';

const lang = {
  DOCUMENT_LIBRARY_ERROR_HEAD:
    'Oops! We’re having a problem with the Document Library',
  DOCUMENT_LIBRARY_ERROR_CONTENT:
    'Don’t worry, all your documents are still safe with us. Please try again later.',
};

function DocListErrorDialog({ hideDialog }) {
  const [visible, setVisible] = useState(false);
  const dialogConfig = dialogConfigFactory({
    heading: lang.DOCUMENT_LIBRARY_ERROR_HEAD,
    content: lang.DOCUMENT_LIBRARY_ERROR_CONTENT,
    visible: true,
    accept: langCommon.OK,
    cancel: null,
  });

  useEffect(() => {
    let timeoutId;

    timeoutId = setTimeout(() => setVisible(true), 0);

    return () => {
      timeoutId = clearInterval(timeoutId);
    };
  });

  return (
    <AlertPopup
      id="DocListErrorDialog"
      title={dialogConfig.heading}
      confirmLabel={dialogConfig.accept}
      cancelLabel={dialogConfig.cancel}
      isOpen={visible}
      onCancel={hideDialog}
      onConfirm={hideDialog}
    >
      {dialogConfig.content}
    </AlertPopup>
  );
}

DocListErrorDialog.propTypes = {
  hideDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = {
  hideDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocListErrorDialog);
