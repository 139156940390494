import styled from 'styled-components';
import { color, breakpoint, zIndex, fontSize } from '@aib/design-tokens';
import IconButton from '@aib/uikit-react/dist/Form/IconButton/IconButton';
import IconRetry from '@aib/uikit-react/dist/Icons/IconRetry';

export const LandscapeOverlayContainer = styled.div`
  position: fixed;
  z-index: ${zIndex.landscapeOverlay};
  top: 0;
  display: none;
  width: 100%;
  height: 100%;
  background-color: ${color.grey07};
  opacity: 0.9;
  background-size: 100%;

  @media (max-height: ${breakpoint.xSmall}) and (min-width: ${breakpoint.small}) {
    display: block;
  }
`;

export const IconButtonStyled = styled(IconButton)`
  color: ${color.baseWhite};
  position: absolute;
  right: 0;
`;

export const IconRetryStyled = styled(IconRetry)`
  left: 0;
  right: 0;
  position: absolute;
  top: 35px;
  margin: auto;
`;

export const LandscapeOverlayText = styled.div`
  position: relative;
  top: 210px;
  color: ${color.baseWhite};
  font-size: ${fontSize.xLarge};
  text-align: center;
  white-space: pre-line;
`;
