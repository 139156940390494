import langCommon from '../../lang.common.json';
import { errorCodes as ERR } from '../../../store/openBanking/openBanking.statusCodes';

const dialogConfig = {
  [ERR.ACCESS_DENIED]: {
    heading: 'You cancelled the connection to your account.',
    content: "We didn't collect any data.",
    msg: null,
    accept: langCommon.OK,
  },
  [ERR.CONNECTION_UNAVAILABLE]: {
    heading: "Can't Connect",
    content: "We can't connect you right now.",
    msg: 'Try again.',
    accept: langCommon.OK,
  },
  [ERR.INTERNAL_ERROR]: {
    heading:
      'Sorry, we weren’t able to automatically get your statements from other banks.',
    content: 'You’ll need to go back and check which ones to add again.',
    msg: '',
    accept: langCommon.OK,
  },
};

export const getDialogConfig = (errorType) => ({
  visible: true,
  cancel: null,
  ...dialogConfig[errorType],
});
