import { map, values, path } from 'ramda';
import {
  put,
  take,
  takeEvery,
  call,
  select,
  fork,
  join,
} from 'redux-saga/effects';
import { fetchPostUploadDocument } from '../api';
import * as types from './docUpload.types';
import * as docUploadActions from './docUpload.actions';
import * as docUploadSelectors from './docUpload.selectors';
import { showDialog } from '../dialog/dialog.actions';
import createDocUploadChannel from './createDocUploadChannel';

// Async calls with side effects(sagas) go here
export const getDocumentsState = (state) => state.docUploadReducer.documents;
/**
 * @public
 * Creates an object list of both applicants' added documents
 *
 * @param {string} documentsState - the portion of state containing the documents
 *
 * @returns {obj} - a list objects containing both applicants' added documents
 */
const mergeAddedDocs = (documentsState, conditionReferenceId) => {
  // create an array containing both applicants' added documents
  const documentsArray = map(
    (applicant) => applicant.added,
    values(path([conditionReferenceId, 'applicants'], documentsState))
  );
  // turn the array into an object list
  return Object.assign({}, ...documentsArray);
};

const generateForm = (
  conditionReferenceId,
  documentTypeId,
  fileData,
  currentApplicantDetails,
  cifKeyToUploadTo
) => {
  const formData = new FormData();
  const { ciRegion, ciType } = currentApplicantDetails;
  // If the docTypeId isn't mapped, then set it to 'Other' so that the doc upload still takes place and lands in MAS:
  const docTypeId = documentTypeId || '1006';
  formData.append('sipConditionId', conditionReferenceId);
  formData.append('docTypeId', docTypeId);
  formData.append('file', fileData);
  formData.append('ciNumber', cifKeyToUploadTo);
  formData.append('ciType', ciType);
  formData.append('ciRegion', ciRegion);

  return formData;
};

function* uploadDoc(uploadDocumentApi, currentDoc) {
  const {
    fileData,
    conditionReferenceId,
    documentTypeId,
    fileUuid,
    currentApplicantDetails,
    cifKeyToUploadTo,
  } = currentDoc;
  try {
    const formData = generateForm(
      conditionReferenceId,
      documentTypeId,
      fileData,
      currentApplicantDetails,
      cifKeyToUploadTo
    );

    yield put(docUploadActions.uploadStarted(fileUuid, conditionReferenceId));

    const channel = yield call(
      createDocUploadChannel,
      uploadDocumentApi().uri,
      formData
    );

    while (true) {
      const response = yield take(channel);
      const { progress = 0, err, success, responsePayload } = response;
      if (err) {
        yield put(
          docUploadActions.uploadFailure(
            fileUuid,
            conditionReferenceId,
            responsePayload.code
          )
        );
        return false;
      }
      if (success) {
        yield put(
          docUploadActions.uploadSuccess(fileUuid, conditionReferenceId)
        );
        return true;
      }
      yield put(
        docUploadActions.uploadProgress(
          fileUuid,
          progress,
          conditionReferenceId
        )
      );
    }
  } catch (e) {
    console.error(e);
    yield put(docUploadActions.uploadFailure(fileUuid, conditionReferenceId));
  }
}

function* uploadAllInParallel(allUploadableDocs, uploadDocumentApi) {
  const uploadResponsesArray = [];

  for (let i = 0; i < allUploadableDocs.length; i++) {
    const currentDoc = allUploadableDocs[i];
    // start all the POST requests in parallel (the fork effect allows parallel requests):
    const didUpload = yield fork(uploadDoc, uploadDocumentApi, currentDoc);
    // push the saga 'task' object that the fork returns into an array:
    uploadResponsesArray.push(didUpload);
  }

  // the join effect waits until all the fork 'tasks' are resolved before returning values:
  return yield join([...uploadResponsesArray]);
}

function* uploadDocs(uploadDocumentApi, data) {
  const documentsState = yield select(getDocumentsState);
  const docs = mergeAddedDocs(documentsState, data.conditionReferenceId);
  // ensure only submittable docs are collected
  const allUploadableDocs = Object.keys(docs).reduce((collector, uuid) => {
    if (docUploadSelectors.docIsSubmittable(docs[uuid])) {
      collector.push(docs[uuid]);
    }
    return collector;
  }, []);

  yield put(
    docUploadActions.uploadsInProgress(true, data.conditionReferenceId)
  );

  const uploadResponses = yield call(
    uploadAllInParallel,
    allUploadableDocs,
    uploadDocumentApi
  );
  // force the uploadResponses to an array
  const successCounter = []
    .concat(uploadResponses)
    .filter((resp) => resp).length;
  const failCounter = allUploadableDocs.length - successCounter;

  yield put(
    docUploadActions.uploadsInProgress(false, data.conditionReferenceId)
  );
  yield put(docUploadActions.submitDocsSuccess(data.conditionReferenceId));

  if (failCounter > 0) {
    yield put(
      showDialog('DOC_UPLOAD_ERROR', {
        failCounter,
        totalFiles: allUploadableDocs.length,
      })
    );
  }
  if (successCounter > 0) {
    yield put(
      showDialog('DOC_UPLOAD_SUCCESS', { numberOfFiles: successCounter })
    );
  }
}

function* uploadSingleDoc(uploadDocumentApi, file) {
  yield put(
    docUploadActions.uploadsInProgress(true, file.conditionReferenceId)
  );

  const uploadSuccess = yield uploadDoc(uploadDocumentApi, file);

  yield put(
    docUploadActions.uploadsInProgress(false, file.conditionReferenceId)
  );
  yield put(docUploadActions.submitDocsSuccess(file.conditionReferenceId));

  if (uploadSuccess) {
    yield put(showDialog('DOC_UPLOAD_SUCCESS', { numberOfFiles: 1 }));
  }
}

function* submitDocsSaga() {
  yield takeEvery(types.SUBMIT_DOCS, uploadDocs, fetchPostUploadDocument);
}

function* submitDocSaga() {
  while (true) {
    const { file } = yield take(types.SUBMIT_DOC);
    yield fork(uploadSingleDoc, fetchPostUploadDocument, file);
  }
}

export {
  uploadDocs,
  uploadDoc,
  uploadAllInParallel,
  submitDocsSaga,
  submitDocSaga,
  uploadSingleDoc,
};
