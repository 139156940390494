import * as tasksActions from './tasks/tasks.actions';
import * as applicationDetailsTypes from './applicationDetails/applicationDetails.types';
import * as persistentStateActions from './persistentState/persistentState.actions';
import * as navigationTypes from './navigation/navigation.types';
import * as navigationActions from './navigation/navigation.actions';
import { getThreads } from './messages/messages.actions';
import { getDocuments } from './documents/documents.actions';

const apiSequencerMiddleware = (store) => (next) => (action) => {
  // eslint-disable-next-line
  if (!action.hasOwnProperty('type')) {
    console.error('action has no type', action);
    return;
  }

  switch (action.type) {
    case applicationDetailsTypes.FETCH_APPLICATION_DETAILS_SUCCESS: {
      next(action);
      store.dispatch(persistentStateActions.fetchPersistentState());
      store.dispatch(tasksActions.fetchTasksFromService());
      store.dispatch(navigationActions.getNavNotifications());
      break;
    }

    // Docs and Threads lists can be large so we load them last
    case navigationTypes.GET_NAV_NOTIFICATIONS_SUCCESS: {
      next(action);
      store.dispatch(getDocuments());
      store.dispatch(getThreads());
      break;
    }

    default:
      next(action);
  }
};

export default apiSequencerMiddleware;
