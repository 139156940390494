import { assoc, assocPath } from 'ramda';

import * as types from './navigation.types';
import * as selectors from './navigation.selectors';

const initialState = {
  visible: true,
  notifications: {},
};

/**
 * @private
 * Updates the state of the navigation visibility in the navigationReducer
 *
 */
export const toggleNavVisibility = (state) => {
  const currentVisibility = selectors.getNavVisibility(state);
  return assoc('visible', !currentVisibility, state);
};

export const updateNotifications = (notifications, state) =>
  assoc('notifications', notifications, state);

export const updateMessageNotifications = (numbMsgNotifications, state) =>
  assocPath(
    ['notifications', 'messagesNotifications'],
    numbMsgNotifications,
    state
  );

const navigationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.TOGGLE_NAV_VISIBILITY:
      return toggleNavVisibility(state);
    case types.GET_NAV_NOTIFICATIONS_SUCCESS:
      return updateNotifications(action.data, state);
    case types.SUB_READ_MESSAGES_FROM_NAV_NOTIFICATIONS:
      return updateMessageNotifications(action.data, state);
    default:
      return state;
  }
};

export default navigationReducer;
