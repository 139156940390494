import Utils from '../../lib/utils';
import { SESSION_IS_NOT_VALID, SESSION_IS_VALID } from '../session';

// initialState
const initialState = Utils.getStateTemplate('appState');

// Reducer
function appState(state = initialState, action = {}) {
  // Currently just a basic pass through reducer as none of our actions alter state
  switch (action.type) {
    case SESSION_IS_VALID:
      return {
        ...state,
        activeSession: true,
      };
    case SESSION_IS_NOT_VALID:
      return {
        ...state,
        activeSession: false,
      };
    default:
      return state;
  }
}

export default appState;
