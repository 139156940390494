import { getStateTemplate } from '../../lib/maps/stateTemplates';

function createLoadingReducer(actions, stateName) {
  const showActions = new Set();
  const map = new Map /* hideAction, showAction */();

  const register = (showAction, ...hideActions) => {
    if (hideActions.length === 0) {
      throw new Error(
        'Each showAction must have one or more corresponding hideActions'
      );
    }

    showActions.add(showAction);
    hideActions.forEach((hideAction) => map.set(hideAction, showAction));
  };

  const dropFirst = (search, array) => {
    let found = false;
    return array.filter((current) => {
      if (!found && current === search) {
        found = true;
        return false;
      }
      return true;
    });
  };

  actions.forEach(([showAction, ...hideActions]) =>
    register(showAction, ...hideActions)
  );

  return (state = getStateTemplate(stateName), action) => {
    if (showActions.has(action.type)) {
      return [...state, action.type];
    } else if (map.has(action.type)) {
      const showAction = map.get(action.type);
      return dropFirst(showAction, state);
    }
    return state;
  };
}

export { createLoadingReducer };
