import { path } from 'ramda';

/**
 * Simplified implementation of the Database migrations pattern used by:
 * https://flywaydb.org/
 */

const allMigrations = {
  '7.0.0': require('./migrations/persistentState.migration.7.0.0.js').default,
  '9.0.0': require('./migrations/persistentState.migration.9.0.0.js').default,
};

function migrate(state = {}) {
  const schemaVersion = path(['__schemaVersion'], state) || '0.0.0';

  let newState = state;
  for (const migrationVersion in allMigrations) {
    const currentMigration = allMigrations[migrationVersion];
    if (schemaVersion < migrationVersion) {
      newState = currentMigration(newState);
    }
  }
  return newState;
}

export default migrate;
