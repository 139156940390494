import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Utils from '../../../lib/utils';

const events = ['click', 'keypress'];

class AutoLogout extends React.Component {
  constructor(props) {
    super(props);
    this.sessionTimeout = Utils.getSetting('SESSION_TIMEOUT');
  }

  componentDidUpdate(prevState) {
    if (prevState.hasActiveSession !== this.props.hasActiveSession)
      this.toggleEventListeners();
  }

  toggleEventListeners = () => {
    const toggleEventListener = this.props.hasActiveSession
      ? document.addEventListener
      : document.removeEventListener;
    events.forEach((event) => {
      toggleEventListener(event, this.restartTimeout);
    });
    if (this.props.hasActiveSession) this.startTimeout();
    else clearTimeout(this.timeoutId);
  };

  startTimeout() {
    this.timeoutId = window.setTimeout(
      this.triggerDialog,
      this.sessionTimeout * 60 * 1000
    );
  }

  restartTimeout = _.throttle(() => {
    clearTimeout(this.timeoutId);
    this.startTimeout();
  }, 1000 * 2);

  triggerDialog = () => {
    clearTimeout(this.timeoutId);
    this.props.showDialog(this.restartTimeout);
  };

  render() {
    return false;
  }
}

AutoLogout.propTypes = {
  showDialog: PropTypes.func.isRequired,
  hasActiveSession: PropTypes.bool,
};

export default AutoLogout;
