const origin = 'applicationDetails';

const FETCH_APPLICATION_DETAILS = `${origin}/fetch`;
const FETCH_APPLICATION_DETAILS_SUCCESS = `${origin}/fetchSuccess`;
const FETCH_APPLICATION_DETAILS_FAILURE = `${origin}/fetchFailure`;

const FETCH_EXISTING_APPLICATION_DETAILS = `${origin}/fetchExistingApplication`;
const FETCH_EXISTING_APPLICATION_DETAILS_SUCCESS = `${origin}/fetchExistingApplicationSuccess`;
const FETCH_EXISTING_APPLICATION_DETAILS_FAILURE = `${origin}/fetchExistingApplicationFailure`;
export {
  FETCH_APPLICATION_DETAILS,
  FETCH_APPLICATION_DETAILS_SUCCESS,
  FETCH_APPLICATION_DETAILS_FAILURE,
  FETCH_EXISTING_APPLICATION_DETAILS,
  FETCH_EXISTING_APPLICATION_DETAILS_SUCCESS,
  FETCH_EXISTING_APPLICATION_DETAILS_FAILURE,
};
