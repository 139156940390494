import styled, { css } from 'styled-components';
import { color, zIndex, fontSize } from '@aib/design-tokens';

export const PageError = styled.div`
  position: fixed;
  z-index: ${zIndex.pageError};
  display: flex;
  justify-content: center;
  width: 100%;
  color: ${color.baseWhite};
  text-align: center;
  background-color: ${color.red40};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14);
  transform: translate(0, -100px);

  ${(props) =>
    props.slideDown &&
    css`
      transform: translate(0, 0);
      transition: transform 500ms ease;
    `}
`;

export const PageErrorInnerContainer = styled.div`
  padding: 0 8px;
  max-width: 450px;
`;

export const PageErrorHeader = styled.p`
  margin: 18px 0 0;
  font-size: ${fontSize.small};
`;

export const PageErrorMessage = styled.p`
  margin-top: 8px;
  font-size: ${fontSize.xSmall};
`;
