import { put, takeEvery, call } from 'redux-saga/effects';
import { fetchTasks } from '../api';
import * as types from './tasks.types';

function* fetchTasksFromService(fetchConditions, successType, failureType) {
  try {
    const response = yield call(fetchConditions);
    if (response.ok) {
      yield put({ type: successType, data: response.resp });
    } else {
      yield put({
        type: failureType,
        err: response.err,
      });
    }
  } catch (e) {
    yield put({ type: failureType });
  }
}

function* tasksSaga() {
  yield takeEvery(
    types.FETCH_TASKS,
    fetchTasksFromService,
    fetchTasks,
    types.FETCH_TASKS_SUCCESS,
    types.FETCH_TASKS_FAILURE
  );
}

export { tasksSaga, fetchTasksFromService };
