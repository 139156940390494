let origin = null;

/* Threads Actions */
origin = 'threadsViewReducer';
const FETCH_THREADS = `${origin}/fetchThreads`;
const FETCH_THREADS_SUCCESS = `${origin}/fetchThreadsSuccess`;
const FETCH_THREADS_FAILURE = `${origin}/fetchThreadsFailure`;
const POST_THREAD = `${origin}/postThread`;
const POST_THREAD_SUCCESS = `${origin}/postThreadSuccess`;
const POST_THREAD_FAILURE = `${origin}/postThreadFailure`;
const UPDATE_THREAD_MODIFIED_DATE = `${origin}/updateThreadModifiedDate`;

/* Messages Actions */
origin = 'messagesReducer';
const DELETE_MESSAGE = `${origin}/deleteMessage`;
const FETCH_THREAD = `${origin}/fetchThread`;
const FETCH_THREAD_IN_PROGRESS = `${origin}/fetchThreadInProgress`;
const FETCH_THREAD_SUCCESS = `${origin}/fetchThreadSuccess`;
const FETCH_THREAD_FAILURE = `${origin}/fetchThreadFailure`;
const RESET_CURRENT_THREAD = `${origin}/resetCurrentThread`;
const SUBMIT_THREAD_REPLY = `${origin}/submitThreadReply`;
const SUBMIT_THREAD_REPLY_SUCCESS = `${origin}/submitThreadReplySuccess`;
const UNSAVED_THREAD_REPLY = `${origin}/unsavedThreadReply`;
const SUBMIT_THREAD_REPLY_FAILURE = `${origin}/submitThreadReplyFailure`;
const MARK_THREAD_READ = `${origin}/markThreadRead`;
const MARK_THREAD_READ_SUCCESS = `${origin}/markThreadReadSuccess`;
const MARK_THREAD_READ_FAILURE = `${origin}/markThreadReadFailure`;
const SHOW_CREATE_MESSAGE = `${origin}/showCreateMessage`;
const HIDE_CREATE_MESSAGE = `${origin}/hideCreateMessage`;
const SWITCH_VIEW_TYPE = 'switchViewType';

export {
  DELETE_MESSAGE,
  FETCH_THREADS,
  FETCH_THREADS_FAILURE,
  FETCH_THREADS_SUCCESS,
  FETCH_THREAD,
  FETCH_THREAD_FAILURE,
  RESET_CURRENT_THREAD,
  FETCH_THREAD_IN_PROGRESS,
  FETCH_THREAD_SUCCESS,
  POST_THREAD,
  POST_THREAD_SUCCESS,
  POST_THREAD_FAILURE,
  UPDATE_THREAD_MODIFIED_DATE,
  SUBMIT_THREAD_REPLY,
  SUBMIT_THREAD_REPLY_SUCCESS,
  SUBMIT_THREAD_REPLY_FAILURE,
  UNSAVED_THREAD_REPLY,
  MARK_THREAD_READ,
  MARK_THREAD_READ_SUCCESS,
  MARK_THREAD_READ_FAILURE,
  SHOW_CREATE_MESSAGE,
  HIDE_CREATE_MESSAGE,
  SWITCH_VIEW_TYPE,
};
