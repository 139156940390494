import { lensPath, over, filter, view, clone, path, set } from 'ramda';
import Utils from '../../lib/utils';
import { hasProp } from '../../lib/utils/typeChecks';
import uploadStatuses from '../../lib/maps/uploadStatuses.json';
import uuidv1 from 'uuid/v1';

/**
 * @public
 * Pinning the unique key prop in obj to a particular key
 *
 * @param {obj} - the key/value set of the activeTask
 * @returns {string} - the key in obj to use as unique
 */
const getDocUpUniqueKey = (obj) => {
  /**
   * this is our predefined unique key property
   * it must exist in activeTask properties
   *
   * keys: [conditionReferenceId ]
   */
  const key = 'conditionReferenceId';
  // we're not likely to intentionally pass garbage
  // but it can happen if there's no activeTask (page refresh issue)
  // so we should spit something out on console
  try {
    if (
      Utils.isEmpty(obj) ||
      !Object.keys(obj).includes(key) ||
      Utils.isEmpty(obj[key])
    ) {
      throw new Error(`${key} not found or empty in obj`);
    }
    return key;
  } catch (err) {
    throw new Error('getDocUpUniqueKey');
  }
};

/**
 * @public
 * Whatever the unique prop is, return a value
 * that uniquely identifies them
 *
 * @param {obj} - the key/value set of the activeTask
 * @returns {obj} - the unique { prop: value }
 *
 * TODO: All state needs is a unique property to add to state
 * if the day comes that conditionReferenceId is not unique enough (probably)
 * we have scope here to change how the unique key is calculated
 * with minimal impact to callers
 */
const getDocUpUniqueKeyVal = (obj) => {
  const docUpUniqueKey = getDocUpUniqueKey(obj);
  return {
    docUpUniqueKey,
    docUpUniqueValue: obj[docUpUniqueKey],
  };
};

/**
 * @public
 * Determine if the applicants added[uuid] is submittable
 * based on deterministic criteria
 *
 * @param {Object} - represents our document object
 *
 * @retuns {Boolean} - `true` if we can submit this doc, `false` otherwise
 */
const docIsSubmittable = (addedDoc) =>
  !hasProp('uploadStatus', addedDoc) ||
  addedDoc.uploadStatus === uploadStatuses.NOT_STARTED;

const isUploadInProgress = (state, conditionReferenceId) => {
  const { documents } = state.docUploadReducer;
  return documents.conditionIdWhereUploadIsInProgress === conditionReferenceId;
};

/**
 * @public
 * Adds to applicant's 'added' object with action data
 *
 * @param {string} uniqueKey - the uniqueKey on state to update
 * @param {obj} actionData - the data object resulting from action
 * @param {obj} state - the state object to append to
 *
 * @returns {obj} - the state object with appending properties
 */
const appendNewFile = (uniqueKey, actionData, state) => {
  const {
    currentApplicant,
    files,
    conditionReferenceId,
    documentTypeId,
    cifKeyToUploadTo,
  } = actionData;

  /**
   * extract added from state *only if* the added file has
   * not already been submitted (uploadStatus == true)
   */
  const docLens = lensPath([
    'documents',
    uniqueKey,
    'applicants',
    currentApplicant,
    'added',
  ]);
  const alreadyAddedFiles = view(docLens, state) || {};

  /**
   * convert alreadyAddedFiles files to an array to
   * enable `some` handy array iterators
   */
  const addedFiles = !Utils.isEmpty(alreadyAddedFiles)
    ? Object.values(alreadyAddedFiles)
    : [];

  const justAddedFiles = !Utils.isEmpty(alreadyAddedFiles)
    ? clone(alreadyAddedFiles)
    : {};

  Object.keys(files).forEach((i) => {
    const alreadyAdded = addedFiles.some(
      (file) =>
        file.fileData.name === files[i].name &&
        file.fileData.size === files[i].size &&
        file.fileData.lastModified === files[i].lastModified
    );
    if (!alreadyAdded) {
      const fileUuid = uuidv1();
      justAddedFiles[`${fileUuid}`] = {
        fileName: files[i].name,
        fileData: files[i],
        conditionReferenceId,
        documentTypeId,
        cifKeyToUploadTo,
        fileUuid,
        currentApplicantDetails: actionData.currentApplicantDetails,
        uploadStatus: uploadStatuses.NOT_STARTED,
        errorCode: null,
      };
    }
  });

  const documentAppended = over(docLens, () => justAddedFiles, state);
  return documentAppended;
};

/**
 * @public
 * Removes from applicant's added object with action data
 *
 * @param {string} uniqueKey - the uniqueKey on state to update
 * @param {obj} actionData - the data object resulting from action
 * @param {obj} state - the state object to remove from
 *
 * @returns {obj} - the state object with appending properties
 */
const removeFileFromAdded = (uniqueKey, actionData, state) => {
  const { currentApplicant, fileUuid } = actionData;
  const addedDocsLens = lensPath([
    'documents',
    uniqueKey,
    'applicants',
    currentApplicant,
    'added',
  ]);
  const isFile = (file) => file.fileUuid !== fileUuid;
  return over(addedDocsLens, filter(isFile), state);
};

/**
 * @public
 * Updates the 'submittedStatus' status of the uploaded document referenced by it's uniqueDocumentId
 *
 * @param {string} uniqueKey - the uniqueKey on state to update
 * @param {string} uniqueDocumentId - the uniqueDocumentId of the uploaded document
 * @param {obj} state - the state object to remove from
 *
 * @returns {obj} - the state object with the updated file object properties
 */
const updateDocument = (uniqueKey, uniqueDocumentId, reducerState, paras) => {
  const currentApplicant = getCurrentApplicant(reducerState);
  const applicantDocLens = lensPath([
    'documents',
    uniqueKey,
    'applicants',
    currentApplicant,
    'added',
    uniqueDocumentId,
  ]);

  const currentDoc = view(applicantDocLens, reducerState);
  if (currentDoc === undefined) {
    return reducerState;
  }
  const updatedDoc = { ...currentDoc, ...paras };
  return set(applicantDocLens, updatedDoc, reducerState);
};

const isDocAdded = (conditionReferenceId, state) =>
  !!path(['docUploadReducer', 'documents', conditionReferenceId], state);

const getApplicants = (conditionReferenceId, state) =>
  path(
    ['docUploadReducer', 'documents', conditionReferenceId, 'applicants'],
    state
  );

const getNumberOfDocsAwaitingUploadByCondition = (
  conditionReferenceId,
  state
) => {
  let counter = 0;
  const applicants = getApplicants(conditionReferenceId, state);
  if (applicants) {
    Object.keys(applicants).forEach((key) => {
      Object.keys(applicants[key].added).forEach((docKey) => {
        if (
          applicants[key].added[docKey].uploadStatus ===
          uploadStatuses.NOT_STARTED
        ) {
          counter++;
        }
      });
    });
  }
  return counter;
};

const getCurrentApplicant = (state = {}) => {
  // Redux is a bit yucky how sometimes state is the full store and sometimes it's the reducer state...
  if (state.docUploadReducer) {
    return state.docUploadReducer?.documents?.currentApplicant;
  } else {
    return state.documents?.currentApplicant;
  }
};

const getShowDialog = (state) =>
  path(['docUploadReducer', 'documents', 'showDialog'], state);

export {
  getDocUpUniqueKey,
  getDocUpUniqueKeyVal,
  appendNewFile,
  removeFileFromAdded,
  updateDocument,
  docIsSubmittable,
  isUploadInProgress,
  isDocAdded,
  getApplicants,
  getShowDialog,
  getCurrentApplicant,
  getNumberOfDocsAwaitingUploadByCondition,
};
