import { clone } from 'ramda';
import {} from './applicationDetails.selectors';

import Utils from '../../lib/utils';
import * as types from './applicationDetails.types';

// initialState
const initialState = Utils.getStateTemplate('appdets');

// capitialize first letter and lowercase the rest.
const capitalizeNames = (data) => {
  const clonedData = clone(data);
  clonedData.applicants.forEach((applicant, idx) => {
    const firstName = clonedData.applicants[idx].firstName;
    clonedData.applicants[idx].firstName = Utils.capitalize(firstName);
  });

  return clonedData;
};

// Reducer
function applicationDetailsReducer(state = initialState, action = {}) {
  // Currently just a basic pass through reducer as none of our actions alter state
  switch (action.type) {
    case types.FETCH_APPLICATION_DETAILS:
      return state;
    case types.FETCH_APPLICATION_DETAILS_SUCCESS: {
      const actionDataCapitializedNames = capitalizeNames(action.data);
      return { ...state, ...actionDataCapitializedNames };
    }
    case types.FETCH_EXISTING_APPLICATION_DETAILS: {
      return { ...state, hasExistingApplicationDataFetched: false };
    }
    case types.FETCH_EXISTING_APPLICATION_DETAILS_SUCCESS: {
      return {
        ...state,
        hasExistingApplicationDataFetched: true,
        ...action.data,
      };
    }

    case types.FETCH_APPLICATION_DETAILS_FAILURE:
      return state;

    default:
      return state;
  }
}

export default applicationDetailsReducer;
