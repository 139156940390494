import { path } from 'ramda';

const getHasViewedWelcomeMessage = (state, ciamId) =>
  path(['persistentStateReducer', 'hasViewedWelcomeMessage', ciamId], state) ===
  true;

const getHasViewedInfoMessage = (state, ciamId) =>
  path(['persistentStateReducer', 'hasViewedInfoMessage', ciamId], state) ===
  true;

const getHasViewedAccessMessage = (state, ciamId) =>
  path(['persistentStateReducer', 'hasViewedAccessMessage', ciamId], state) ===
  true;
const getDraftMessageByThreadId = (state, threadId) => {
  return path(
    ['persistentStateReducer', 'threadsWithDraftMessages', threadId],
    state
  );
};

// const NOT_SATISFIED = 32002;
const VERIFIED = 32004;
const NOT_REQUIRED = 32003;
const PENDING_VERIFICATION = 10000;

// MAS has no value for Pending Verification so we store it in the Oracle DB
function getLatestConditionStatusId(
  conditionReferenceId,
  masConditionStatusId,
  state
) {
  const conditionIdsWithDocsUploaded =
    path(['persistentStateReducer', 'conditionIdsWithDocsUploaded'], state) ||
    {};

  // If the condition is done, we don't care about the persistentState data
  if (
    masConditionStatusId === VERIFIED ||
    masConditionStatusId === NOT_REQUIRED
  ) {
    return masConditionStatusId;
  }

  // State service says docs have been uploaded so show "Pending Verification"
  // This is replicating the existing bug: https://jira.aib.pri/browse/HO-4232
  // So if the status goes to 'Query' in MAS, this isn't reflected on screen
  if (conditionIdsWithDocsUploaded[conditionReferenceId]) {
    return PENDING_VERIFICATION;
  }
  return masConditionStatusId;
}

export {
  getHasViewedWelcomeMessage,
  getHasViewedInfoMessage,
  getDraftMessageByThreadId,
  getLatestConditionStatusId,
  getHasViewedAccessMessage,
};
