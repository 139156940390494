import React from 'react';
import * as S from './CloudAnimations.styled.js';

export const CloudAnimations = () => {
  return (
    <React.Fragment>
      <S.Clouds1a />
      <S.Clouds1b />
      <S.Clouds2a />
      <S.Clouds2b />
    </React.Fragment>
  );
};
