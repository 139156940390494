import React from 'react';
import PropTypes from 'prop-types';
import IconAlert from '@aib/uikit-react/dist/Icons/IconAlert';
import * as S from './BrowserWarning.styled.js';

const upgradeLinksHtml = (
  <S.BrowserSupportText>
    For the best browser experience please use &nbsp;
    <a href="https://www.google.com/chrome/">Chrome</a>, &nbsp;
    <a href="http://www.mozilla.org/firefox/">Firefox</a>, &nbsp;
    <a href="http://www.apple.com/safari/">Safari</a>
    &nbsp;or <a href="http://www.browserfordoing.com/">Microsoft Edge</a>
    &nbsp;.
  </S.BrowserSupportText>
);

const warnings = {
  BROWSER_NOT_SUPPORTED: (
    <span>
      <S.BrowserSupportText>
        Your browser will not work with our service.
      </S.BrowserSupportText>
      {upgradeLinksHtml}
    </span>
  ),
  BROWSER_VERSION_NOT_SUPPORTED: (
    <span>
      <S.BrowserSupportText>
        This version of your browser will not work well with our service.
      </S.BrowserSupportText>
      <S.BrowserSupportText>
        For the best experience please upgrade your browser version.
      </S.BrowserSupportText>
    </span>
  ),
  BROWSER_VERSION_PARTIALLY_SUPPORTED: (
    <span>
      <S.BrowserSupportText>
        This version of your browser might not work well with our service.
      </S.BrowserSupportText>
      <S.BrowserSupportText>
        For the best experience please upgrade your browser version.
      </S.BrowserSupportText>
    </span>
  ),
};

function BrowserWarning(props) {
  const { type } = props;

  return (
    <S.BrowserSupport>
      <S.BrowserSupportContent>
        <S.BrowserSupportIcon>
          <IconAlert />
        </S.BrowserSupportIcon>
        {warnings[type]}
      </S.BrowserSupportContent>
    </S.BrowserSupport>
  );
}

BrowserWarning.propTypes = {
  type: PropTypes.string.isRequired,
};

export default BrowserWarning;
