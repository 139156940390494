import Utils from '../../lib/utils';

/**
 * Defines the initial state
 * structure for each reducer
 */

/** ************  SHOW LOADING COMPONENT ************ */
// List of actions corresponding to in-progress ajax requests
// If any requests are in progress, we show a "Loading" graphic
const fullScreenLoadingState = [];
const backgroundLoadingState = [];

/** ************  SHOW SNACKBAR COMPONENT ************ */
// List of snackbar objects: message text etc
const snackbarState = [];
const dialogState = [];

/** ************  SYSTEM MAINTENANCE ************ */
const systemMaintenanceState = {};

/** ************  TASKS ************ */
const tasksState = {
  tasks: [],
};

/** ************  UPLOAD ************ */
// root state for docUpload
const uploadState = {
  // <-- attach keyed instances of uploadDoctypeState{} keyed by docTypeId
  documents: {
    currentApplicant: '1',
    conditionIdWhereUploadIsInProgress: 0,
  },
};

/**
 * Separate chunks of docUp docType state so we
 * can combine them how we need dependent on applicants
 */

// state tree for upload doctype attaching to uploadState
const uploadDoctypeState = {
  docsSubmitted: false,
  uploadInProcess: false,
  applicants: null, // <--- attach instances of uploadApplicantsState{} keyed by Int
};

/**
 * this will get attached to uploadDoctypeState
 * once the number of applicants is known
 */
// state tree per docType upload attaching to uploadDoctypeState
const uploadApplicantState = {
  applicantName: 'DOCUP_TABS_TAB_LABEL', // placeholder for applicant name
  added: {},
};

/** ************  DOWNLOAD ************ */
const downloadState = {};

/** ************  MESSAGES ************ */
const messagesState = {
  threads: [],
  thread: {},
  currentThread: '',
  areMessagesLoading: false,
  isMessageSending: false,
  isCreateMessageVisible: false,
};

/** ************  DOCUMENTS ************ */
const documentsState = {
  aibDocuments: [],
  applicantDocuments: [],
};

/** ************  APPLICATION DETAILS ************ */
const applicationDetailsState = {
  applicationId: null,
  statusCode: null,
  statusDescription: null,
  applicants: [],
  hasExistingApplicationDataFetched: false,
  hasApprovalInPrinciple: null,
  hasSavedApplications: null,
};

/** ************  OPEN BANKING ************ */
const openBankingState = {
  accordionState: { auto: true, manual: false },
  showModal: false,
  step: 0,
  providers: [],
  isPending: false,
};

/** ************  CIAM BANKING ************ */
const ciamState = {
  applicationConnectionFailed: false,
  profile: null,
};

/** ************  APP STATE ************ */
const appState = {
  activeSession: null,
};

/** ************  MAP SPEC ************ */
// KS: Lets get rid of these
const map = new Map([
  ['fullScreenLoading', fullScreenLoadingState],
  ['backgroundLoading', backgroundLoadingState],
  ['snackbar', snackbarState],
  ['dialog', dialogState],
  ['tasks', tasksState],
  ['systemMaintenance', systemMaintenanceState],
  ['docup', uploadState],
  ['docdown', downloadState],
  ['messages', messagesState],
  ['documents', documentsState],
  ['appdets', applicationDetailsState],
  ['openBanking', openBankingState],
  ['ciam', ciamState],
  ['appState', appState],
]);

// docup children - we don't merge parent objects
map.set('docup_applicant', uploadApplicantState);
map.set('docup_doctype', uploadDoctypeState);

export const getStateTemplate = (key) => Utils.getStateTemplate(key);
export default map;
