import { put, takeLatest, call, take } from 'redux-saga/effects';
import * as types from './persistentState.types';
import * as actions from './persistentState.actions';
import { fetchPersistentState, putPersistentState } from '../api';

function* fetchThePersistentState(fetchPersistentStateFunc) {
  try {
    const result = yield call(fetchPersistentStateFunc);
    if (result.ok) {
      yield put({
        type: types.STATE_GET_SUCCESS,
        data: result.resp,
      });
    } else {
      yield put({
        type: types.STATE_GET_FAILURE,
        err: result.err,
      });
    }
  } catch (e) {
    // console.error('fetchThePersistentState:', e);
    yield put({
      type: types.STATE_GET_FAILURE,
      err: e,
    });
  }
}

function* fetchPersistentStateSaga() {
  yield takeLatest(
    types.STATE_GET,
    fetchThePersistentState,
    fetchPersistentState
  );
}

function* putThePersistentState(putPersistentStateFunc, action) {
  try {
    const result = yield call(putPersistentStateFunc, action.data);
    if (result.ok) {
      yield put({
        type: types.STATE_PUT_SUCCESS,
        data: result.resp,
      });
    } else {
      yield put({
        type: types.STATE_PUT_FAILURE,
        err: result.err,
      });
    }
  } catch (e) {
    // console.error('putThePersistentState:', e);
    yield put({
      type: types.STATE_PUT_FAILURE,
      err: e,
    });
  }
}

function* putPersistentStateSaga() {
  yield takeLatest(types.STATE_PUT, putThePersistentState, putPersistentState);
}

function* updateTaskStateSaga() {
  const result = yield take(types.TASK_STATE_UPDATE);
  yield put(actions.storeMasState(result.conditionRefId));
  yield put(actions.syncStateWithMas());
}

export {
  fetchPersistentStateSaga,
  fetchThePersistentState,
  putPersistentStateSaga,
  putThePersistentState,
  updateTaskStateSaga,
};
