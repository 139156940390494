import * as types from './openBanking.types';
import Utils from '../../lib/utils';

const initialState = Utils.getStateTemplate('openBanking');

const openBankingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.TOGGLE_ACCORDION: {
      const { accordionId } = action;
      return {
        ...state,
        accordionState: {
          auto: false,
          manual: false,
          [accordionId]: !state.accordionState[accordionId],
        },
      };
    }
    case types.CLOSE_ACCORDION: {
      return { ...state, accordionState: { auto: false, manual: false } };
    }
    case types.SHOW_MODAL:
      return { ...state, step: 0, showModal: true };
    case types.HIDE_MODAL:
      return { ...state, showModal: false };
    case types.NEXT_STEP:
      return { ...state, step: state.step + 1 };
    case types.PREV_STEP:
      return { ...state, step: state.step > 0 ? state.step - 1 : 0 };

    // fetch providers
    case types.FETCH_PROVIDERS:
      return { ...state, isPending: true };
    case types.FETCH_PROVIDERS_SUCCESS:
      return {
        ...state,
        isPending: false,
        providers: action.data.openBankingProviders,
      };

    // fetch provider url
    case types.FETCH_PROVIDER_URL:
      return { ...state, isPending: true };
    case types.FETCH_PROVIDER_URL_SUCCESS:
      return { ...state, isPending: false };
    case types.FETCH_PROVIDER_URL_FAILURE:
      return { ...state, isPending: false };

    case types.SELECT_PROVIDER:
      return {
        ...state,
        provider: action.data,
      };
    default:
      return state;
  }
};

export default openBankingReducer;
