import { call, put, takeLatest } from 'redux-saga/effects';
import { refreshSession, logoutEndpoint } from './api';
import CookieUtils from '../lib/cookieUtils';

// Action Types
export const CONFIRM_LOGOUT_CLICK = 'sessionReducer/confirmLogoutClick';
export const REFRESH_SESSION = 'sessionReducer/refreshSession';
export const AUTO_LOGOUT = 'sessionReducer/autoLogout';
export const SESSION_IS_VALID = 'sessionReducer/sessionIsValid';
export const SESSION_IS_NOT_VALID = 'sessionReducer/sessionIsNotValid';

const createAction = (type) => (payload) => ({ type, ...payload });

// Actions
export const confirmLogoutClickAction = createAction(CONFIRM_LOGOUT_CLICK);
export const refreshSessionAction = createAction(REFRESH_SESSION);
export const autoLogoutAction = createAction(AUTO_LOGOUT);
export const sessionIsValid = createAction(SESSION_IS_VALID);
export const sessionIsNotValid = createAction(SESSION_IS_NOT_VALID);

export const reloadSplashPage = (isAutoLogout) => {
  if (
    location.pathname?.endsWith('intro') ||
    location.pathname?.endsWith('apply')
  ) {
    window.location = '/mortgages/logout-landing';
    return;
  }
  if (isAutoLogout) {
    window.location = '/?error=session-expired';
  } else {
    window.location = '/';
  }
};

const handleLogoutFailure = (isAutoLogout) => {
  CookieUtils.deleteCsrfCookies();
  reloadSplashPage(isAutoLogout);
};

// Worker Saga
export function* logoutWorker(isAutoLogout) {
  try {
    const response = yield call(logoutEndpoint);
    if (response.ok) {
      reloadSplashPage(isAutoLogout);
      sessionStorage.clear();
    } else {
      handleLogoutFailure(isAutoLogout);
    }
  } catch (e) {
    console.error('Error logging out', e);
    handleLogoutFailure();
  }
}

export function* refreshSessionWorker(payload) {
  const response = yield call(refreshSession, payload?.autoRedirect);
  if (response.ok) yield put(sessionIsValid());
  else yield put(sessionIsNotValid());
}

// Side Effects/Saga
export function* logoutSaga() {
  yield takeLatest(CONFIRM_LOGOUT_CLICK, logoutWorker, false);
  yield takeLatest(REFRESH_SESSION, refreshSessionWorker);
  yield takeLatest(AUTO_LOGOUT, logoutWorker, true);
}
