import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideDialog } from '../../../store/dialog/dialog.actions';
import AlertPopup from '@aib/uikit-react/dist/Content/AlertPopup/AlertPopup';
import dialogConfigFactory from './dialogConfigFactory';
import langCommon from '../../lang.common.json';

const lang = {
  SUCCESS_HEAD_SINGULAR: 'Thanks, we got your document.',
  SUCCESS_CONTENT_SINGULAR:
    "We're checking it and will let you know when it's OK.",
  SUCCESS_CONTENT_PLURAL:
    "We're checking it and will let you know when they're OK.",
};

function DocUploadSuccessDialog({ hideDialog, numberOfFiles }) {
  const [visible, setVisible] = useState(false);
  let heading;
  let content;

  if (numberOfFiles > 1) {
    heading = `Thanks, we got your ${numberOfFiles} documents.`;
    content = lang.SUCCESS_CONTENT_PLURAL;
  } else {
    heading = lang.SUCCESS_HEAD_SINGULAR;
    content = lang.SUCCESS_CONTENT_SINGULAR;
  }

  const dialogConfig = dialogConfigFactory({
    heading,
    content,
    visible: true,
    accept: langCommon.OK,
    cancel: null,
  });

  useEffect(() => {
    let timeoutId;
    timeoutId = setTimeout(() => setVisible(true), 0);

    return () => {
      timeoutId = clearInterval(timeoutId);
    };
  });

  return (
    <AlertPopup
      id="DocUploadSuccessDialog"
      title={dialogConfig.heading}
      confirmLabel={dialogConfig.accept}
      cancelLabel={dialogConfig.cancel}
      isOpen={visible}
      onConfirm={hideDialog}
      onCancel={hideDialog}
    >
      {dialogConfig.content}
    </AlertPopup>
  );
}

DocUploadSuccessDialog.propTypes = {
  hideDialog: PropTypes.func.isRequired,
  numberOfFiles: PropTypes.number.isRequired,
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = {
  hideDialog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocUploadSuccessDialog);
