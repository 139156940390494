import * as types from './navigation.types';

const toggleNavVisibility = () => ({ type: types.TOGGLE_NAV_VISIBILITY });

const getNavNotifications = () => ({ type: types.GET_NAV_NOTIFICATIONS });

const getNavNotificationsSuccess = (data) => ({
  type: types.GET_NAV_NOTIFICATIONS_SUCCESS,
  data,
});

const getNavNotificationsFailure = (err) => ({
  type: types.GET_NAV_NOTIFICATIONS_FAILURE,
  err,
});

const subReadMessagesFromNotifications = (
  currentNotifications,
  currentUnreadCount
) => {
  const newMessageNotifications =
    parseInt(currentNotifications, 10) > 0
      ? parseInt(currentNotifications, 10) - parseInt(currentUnreadCount, 10)
      : 0;
  return {
    type: types.SUB_READ_MESSAGES_FROM_NAV_NOTIFICATIONS,
    data: newMessageNotifications,
  };
};

export {
  toggleNavVisibility,
  getNavNotifications,
  getNavNotificationsSuccess,
  getNavNotificationsFailure,
  subReadMessagesFromNotifications,
};
