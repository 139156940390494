import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideDialog } from '../../../store/dialog/dialog.actions';
import AlertPopup from '@aib/uikit-react/dist/Content/AlertPopup/AlertPopup';
import {
  confirmLogoutClickAction,
  refreshSessionAction,
  autoLogoutAction,
} from '../../../store/session';
import Utils from '../../../lib/utils';
import dialogConfigFactory from './dialogConfigFactory';

function AutoLogoutDialog({ onAccept, onCancel, onAutoLogout }) {
  const [visible, setVisible] = useState(false);
  const [warningTime, setWarningTime] = useState(
    Utils.getSetting('AUTO_LOGOUT_WARNING_TIME')
  );

  useEffect(() => {
    let timeoutId;
    timeoutId = setTimeout(() => setVisible(true), 0);
    const intervalId = window.setInterval(() => {
      if (warningTime > 0) {
        setTimeout(() => setWarningTime(warningTime - 1), 0);
      } else {
        onAutoLogout();
      }
    }, 1000);

    return () => {
      timeoutId = clearInterval(timeoutId);
      clearInterval(intervalId);
    };
  });

  const dialogConfig = dialogConfigFactory({
    heading: 'Automatic Logout',
    content: `You will be logged out in ${warningTime.toString()} seconds due to inactivity.`,
    visible: true,
    accept: 'Log Out',
    cancel: 'Stay Logged In',
  });

  return (
    <AlertPopup
      id="AutoLogoutDialog"
      title={dialogConfig.heading}
      confirmLabel={dialogConfig.accept}
      cancelLabel={dialogConfig.cancel}
      isOpen={visible}
      onConfirm={onAccept}
      onCancel={onCancel}
    >
      {dialogConfig.content}
    </AlertPopup>
  );
}

AutoLogoutDialog.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAutoLogout: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onAccept: () => {
    dispatch(hideDialog());
    dispatch(confirmLogoutClickAction());
  },
  onCancel: () => {
    ownProps.onRefresh();
    dispatch(hideDialog());
    dispatch(refreshSessionAction());
  },
  onAutoLogout: () => {
    dispatch(autoLogoutAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoLogoutDialog);
