import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideDialog } from '../../../store/dialog/dialog.actions';
import AlertPopup from '@aib/uikit-react/dist/Content/AlertPopup/AlertPopup';

function OpenBankingPdfGenerated({ hideDialog }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timeoutId;

    timeoutId = setTimeout(() => setVisible(true), 0);

    return () => {
      timeoutId = clearInterval(timeoutId);
    };
  });

  return (
    <AlertPopup
      id="PdfDocumentGenerationComplete"
      title="Thanks, we got your statement."
      confirmLabel="OK"
      cancelLabel={null}
      isOpen={visible}
      onConfirm={hideDialog}
      onCancel={hideDialog}
    />
  );
}

OpenBankingPdfGenerated.propTypes = (state) => ({
  hideDialog: PropTypes.func.isRequired,
});

export const mapDispatchToProps = {
  hideDialog,
};

export default connect(null, mapDispatchToProps)(OpenBankingPdfGenerated);
