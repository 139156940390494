import { put, takeEvery, call } from 'redux-saga/effects';
import { fetchNavNotifications } from '../api';
import * as types from './navigation.types';
import {
  getNavNotificationsSuccess,
  getNavNotificationsFailure,
} from './navigation.actions';

function* getNotifications(fetchNotifications) {
  try {
    const response = yield call(fetchNotifications);
    if (response.ok) {
      const data = response.resp;
      yield put(getNavNotificationsSuccess(data));
    } else {
      yield put(getNavNotificationsFailure(response));
    }
  } catch (err) {
    yield put(getNavNotificationsFailure(err));
  }
}

function* getNavNotifications() {
  yield takeEvery(
    types.GET_NAV_NOTIFICATIONS,
    getNotifications,
    fetchNavNotifications
  );
}

export { getNotifications, getNavNotifications };
