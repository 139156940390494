import { connect } from 'react-redux';
import withRedux from '../../../withRedux';
import ConnectApplication from './ConnectApplication';
import {
  connectApplication,
  resetConnectApplicationError,
} from '../../../store/ciam/ciam.actions';
import { getApplicationConnectFailed } from '../../../store/ciam/ciam.selectors';

export const QUERY_STRING_APP_ID = 'appId';

const mapStateToProps = (state) => ({
  hasConnectFailed: getApplicationConnectFailed(state),
});

const mapDispatchToProps = (dispatch) => ({
  connectApplication: (id) => {
    const search = new URLSearchParams(location.search);
    const oldAppId = search.get(QUERY_STRING_APP_ID);
    dispatch(connectApplication(id, oldAppId));
  },
  closeErrorModal: () => {
    dispatch(resetConnectApplicationError());
  },
});

export default withRedux(
  connect(mapStateToProps, mapDispatchToProps)(ConnectApplication)
);
